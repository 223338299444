import classNames from "classnames";
import React from "react";
// @ts-expect-error
import styles from './Notification.module.scss'

type HeadingVariant = "normal" | "critical";
interface NotificationProps {
    text: string;
    suffix?: string;
    prefix?: string;
    variant: HeadingVariant;
    className?: string;
}

const Notification = ({
    text,
    suffix,
    prefix,
    variant,
    className,
}: NotificationProps) => {
    return (
        <div className="relative inline-flex whitespace-nowrap">
            <div
                className={classNames(
                    "text-[48px] font-pawlskinny relative flex items-center",
                    className
                )}
            >
                {prefix &&
                    <span
                        className={classNames(
                            "text-[36px] leading-[0.875em] uppercase block z-30 tracking-wider layerBackground",
                            styles.layerBackground,
                            variant === 'normal' && styles.layerBackgroundNormal,
                            variant === 'critical' && styles.layerBackgroundCritical,
                        )}
                        data-content={prefix}
                    >
                        <span className={styles.layerForeground} data-content={prefix}>
                            {prefix}
                        </span>
                    </span>
                }
                <span
                    className={classNames(
                        "leading-[0.875em] uppercase block z-30 tracking-wider layerBackground",
                        styles.layerBackground,
                        variant === 'normal' && styles.layerBackgroundNormal,
                        variant === 'critical' && styles.layerBackgroundCritical,
                    )}
                    data-content={text}
                >
                    <span className={styles.layerForeground} data-content={text}>
                        {text}
                    </span>
                </span>
                {suffix &&
                    <span
                        className={classNames(
                            "text-[36px] leading-[0.875em] uppercase block z-30 tracking-wider layerBackground",
                            styles.layerBackground,
                            variant === 'normal' && styles.layerBackgroundNormal,
                            variant === 'critical' && styles.layerBackgroundCritical,
                        )}
                        data-content={suffix}
                    >
                        <span className={styles.layerForeground} data-content={suffix}>
                            {suffix}
                        </span>
                    </span>
                }
            </div>
        </div>
    );
};

export default Notification;
