import React, { useEffect, useState } from 'react'

import { AFrameScene } from '../lib/aframe-components'
// @ts-expect-error
import html from './cube.html'
import { Game } from '../components/Game'
import '../aframe/bullet'
import '../aframe/clone'
import '../aframe/tracker'
import { Loading } from '../components/Loading'

const Scene = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedDelay, setIsLoadedDelay] = useState(false)

  const handleLoaded = () => {
    setIsLoaded(true)
  }

  const handleLoadedDelay = () => {
    setIsLoadedDelay(true)
  }

  return (
    <>
      <AFrameScene sceneHtml={html} handleLoaded={handleLoaded} />
      <Loading isLoaded={isLoaded} handleLoaded={handleLoadedDelay} />
      {isLoaded && <Game isLoaded={isLoadedDelay} />}
    </>
  )
}

export { Scene }
