import React from "react";

function GaugeBullets(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="162"
            height="56"
            fill="none"
            viewBox="0 0 162 56"
            {...props}
        >
            <path
                fill="#282800"
                fillOpacity="0.6"
                fillRule="evenodd"
                d="M151 8H51L11 48h100l40-40z"
                clipRule="evenodd"
            ></path>
            <g filter="url(#filter0_dd_3_81)">
                <mask
                    id="path-2-outside-1_3_81"
                    width="150"
                    height="44"
                    x="6"
                    y="6"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M6 6H156V50H6z"></path>
                    <path
                        fillRule="evenodd"
                        d="M151 8H51L11 48h100l40-40z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    d="M51 8l-1.414-1.414.586-.586H51v2zm100 0V6h4.828l-3.414 3.414L151 8zM11 48v2H6.172l3.414-3.414L11 48zm100 0l1.414 1.414-.586.586H111v-2zM51 6h100v4H51V6zM9.586 46.586l40-40 2.828 2.828-40 40-2.828-2.828zM111 50H11v-4h100v4zm41.414-40.586l-40 40-2.828-2.828 40-40 2.828 2.828z"
                    mask="url(#path-2-outside-1_3_81)"
                ></path>
            </g>
            <g filter="url(#filter1_dd_3_81)">
                <mask
                    id="path-4-outside-2_3_81"
                    width="44"
                    height="34"
                    x="18"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M18 11H62V45H18z"></path>
                    <path
                        fillRule="evenodd"
                        d="M53 12h6L27 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#282800"
                    fillRule="evenodd"
                    d="M53 12h6L27 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#FEFA00"
                    fillOpacity="0.6"
                    d="M59 12l.707.707L61.414 11H59v1zm-6 0v-1h-.414l-.293.293L53 12zM27 44v1h.414l.293-.293L27 44zm-6 0l-.707-.707L18.586 45H21v-1zm38-33h-6v2h6v-2zM27.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM21 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-4-outside-2_3_81)"
                ></path>
            </g>
            <g filter="url(#filter2_dd_3_81)">
                <mask
                    id="path-6-outside-3_3_81"
                    width="44"
                    height="34"
                    x="27"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M27 11H71V45H27z"></path>
                    <path
                        fillRule="evenodd"
                        d="M62 12h6L36 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#282800"
                    fillRule="evenodd"
                    d="M62 12h6L36 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#FEFA00"
                    fillOpacity="0.6"
                    d="M68 12l.707.707L70.414 11H68v1zm-6 0v-1h-.414l-.293.293L62 12zM36 44v1h.414l.293-.293L36 44zm-6 0l-.707-.707L27.586 45H30v-1zm38-33h-6v2h6v-2zM36.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM30 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-6-outside-3_3_81)"
                ></path>
            </g>
            <g filter="url(#filter3_dd_3_81)">
                <mask
                    id="path-8-outside-4_3_81"
                    width="44"
                    height="34"
                    x="36"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M36 11H80V45H36z"></path>
                    <path
                        fillRule="evenodd"
                        d="M71 12h6L45 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#282800"
                    fillRule="evenodd"
                    d="M71 12h6L45 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#FEFA00"
                    fillOpacity="0.6"
                    d="M77 12l.707.707L79.414 11H77v1zm-6 0v-1h-.414l-.293.293L71 12zM45 44v1h.414l.293-.293L45 44zm-6 0l-.707-.707L36.586 45H39v-1zm38-33h-6v2h6v-2zM45.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM39 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-8-outside-4_3_81)"
                ></path>
            </g>
            <g filter="url(#filter4_dd_3_81)">
                <mask
                    id="path-10-outside-5_3_81"
                    width="44"
                    height="34"
                    x="45"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M45 11H89V45H45z"></path>
                    <path
                        fillRule="evenodd"
                        d="M80 12h6L54 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M80 12h6L54 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M86 12l.707.707L88.414 11H86v1zm-6 0v-1h-.414l-.293.293L80 12zM54 44v1h.414l.293-.293L54 44zm-6 0l-.707-.707L45.586 45H48v-1zm38-33h-6v2h6v-2zM54.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM48 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-10-outside-5_3_81)"
                ></path>
            </g>
            <g filter="url(#filter5_dd_3_81)">
                <mask
                    id="path-12-outside-6_3_81"
                    width="44"
                    height="34"
                    x="54"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M54 11H98V45H54z"></path>
                    <path
                        fillRule="evenodd"
                        d="M89 12h6L63 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M89 12h6L63 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M95 12l.707.707L97.414 11H95v1zm-6 0v-1h-.414l-.293.293L89 12zM63 44v1h.414l.293-.293L63 44zm-6 0l-.707-.707L54.586 45H57v-1zm38-33h-6v2h6v-2zM63.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM57 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-12-outside-6_3_81)"
                ></path>
            </g>
            <g filter="url(#filter6_dd_3_81)">
                <mask
                    id="path-14-outside-7_3_81"
                    width="44"
                    height="34"
                    x="63"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M63 11H107V45H63z"></path>
                    <path
                        fillRule="evenodd"
                        d="M98 12h6L72 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M98 12h6L72 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M104 12l.707.707L106.414 11H104v1zm-6 0v-1h-.414l-.293.293L98 12zM72 44v1h.414l.293-.293L72 44zm-6 0l-.707-.707L63.586 45H66v-1zm38-33h-6v2h6v-2zM72.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM66 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-14-outside-7_3_81)"
                ></path>
            </g>
            <g filter="url(#filter7_dd_3_81)">
                <mask
                    id="path-16-outside-8_3_81"
                    width="44"
                    height="34"
                    x="72"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M72 11H116V45H72z"></path>
                    <path
                        fillRule="evenodd"
                        d="M107 12h6L81 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M107 12h6L81 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M113 12l.707.707L115.414 11H113v1zm-6 0v-1h-.414l-.293.293L107 12zM81 44v1h.414l.293-.293L81 44zm-6 0l-.707-.707L72.586 45H75v-1zm38-33h-6v2h6v-2zM81.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM75 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-16-outside-8_3_81)"
                ></path>
            </g>
            <g filter="url(#filter8_dd_3_81)">
                <mask
                    id="path-18-outside-9_3_81"
                    width="44"
                    height="34"
                    x="81"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M81 11H125V45H81z"></path>
                    <path
                        fillRule="evenodd"
                        d="M116 12h6L90 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M116 12h6L90 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M122 12l.707.707L124.414 11H122v1zm-6 0v-1h-.414l-.293.293L116 12zM90 44v1h.414l.293-.293L90 44zm-6 0l-.707-.707L81.586 45H84v-1zm38-33h-6v2h6v-2zM90.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM84 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-18-outside-9_3_81)"
                ></path>
            </g>
            <g filter="url(#filter9_dd_3_81)">
                <mask
                    id="path-20-outside-10_3_81"
                    width="44"
                    height="34"
                    x="90"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M90 11H134V45H90z"></path>
                    <path
                        fillRule="evenodd"
                        d="M125 12h6L99 44h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M125 12h6L99 44h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M131 12l.707.707L133.414 11H131v1zm-6 0v-1h-.414l-.293.293L125 12zM99 44v1h.414l.293-.293L99 44zm-6 0l-.707-.707L90.586 45H93v-1zm38-33h-6v2h6v-2zM99.707 44.707l32-32-1.414-1.414-32 32 1.414 1.414zM93 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-20-outside-10_3_81)"
                ></path>
            </g>
            <g filter="url(#filter10_dd_3_81)">
                <mask
                    id="path-22-outside-11_3_81"
                    width="44"
                    height="34"
                    x="99"
                    y="11"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M99 11H143V45H99z"></path>
                    <path
                        fillRule="evenodd"
                        d="M134 12h6l-32 32h-6l32-32z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M134 12h6l-32 32h-6l32-32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#282800"
                    d="M140 12l.707.707L142.414 11H140v1zm-6 0v-1h-.414l-.293.293L134 12zm-26 32v1h.414l.293-.293L108 44zm-6 0l-.707-.707L99.586 45H102v-1zm38-33h-6v2h6v-2zm-31.293 33.707l32-32-1.414-1.414-32 32 1.414 1.414zM102 45h6v-2h-6v2zm.707-.293l32-32-1.414-1.414-32 32 1.414 1.414z"
                    mask="url(#path-22-outside-11_3_81)"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_dd_3_81"
                    width="161.657"
                    height="56"
                    x="0.172"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_3_81"
                    width="56"
                    height="46"
                    x="12"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter2_dd_3_81"
                    width="56"
                    height="46"
                    x="21"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter3_dd_3_81"
                    width="56"
                    height="46"
                    x="30"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter4_dd_3_81"
                    width="56"
                    height="46"
                    x="39"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter5_dd_3_81"
                    width="56"
                    height="46"
                    x="48"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter6_dd_3_81"
                    width="56"
                    height="46"
                    x="57"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter7_dd_3_81"
                    width="56"
                    height="46"
                    x="66"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter8_dd_3_81"
                    width="56"
                    height="46"
                    x="75"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter9_dd_3_81"
                    width="56"
                    height="46"
                    x="84"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter10_dd_3_81"
                    width="56"
                    height="46"
                    x="93"
                    y="5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3_81"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_3_81"
                        result="effect2_dropShadow_3_81"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_3_81"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default GaugeBullets;