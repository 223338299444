import React from "react";

function CountDown(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="172"
            height="173"
            fill="none"
            viewBox="0 0 172 173"
            {...props}
        >
            <circle
                cx="86"
                cy="86.686"
                r="64"
                fill="#282800"
                fillOpacity="0.4"
            ></circle>
            <g filter="url(#filter0_dd_4_6)">
                <circle
                    cx="86"
                    cy="86.686"
                    r="62.933"
                    stroke="#FEFA00"
                    strokeWidth="2.133"
                ></circle>
            </g>
            <g filter="url(#filter1_dd_4_6)" opacity="0.6">
                <circle
                    cx="86"
                    cy="86.686"
                    r="69"
                    stroke="#FEFA00"
                    strokeWidth="2"
                ></circle>
            </g>
            <g filter="url(#filter2_dd_4_6)" opacity="0.2">
                <path
                    stroke="#FEFA00"
                    strokeWidth="2"
                    d="M165 86.686c0 43.631-35.37 79.001-79 79.001s-79-35.37-79-79c0-43.631 35.37-79 79-79s79 35.369 79 79z"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_dd_4_6"
                    width="140.8"
                    height="140.8"
                    x="15.6"
                    y="16.287"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3.2"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4_6"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.6"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_4_6"
                        result="effect2_dropShadow_4_6"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_4_6"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_4_6"
                    width="152"
                    height="152"
                    x="10"
                    y="10.687"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4_6"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_4_6"
                        result="effect2_dropShadow_4_6"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_4_6"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter2_dd_4_6"
                    width="172"
                    height="172"
                    x="0"
                    y="0.687"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4_6"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_4_6"
                        result="effect2_dropShadow_4_6"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_4_6"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default CountDown;