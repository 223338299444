import { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { IceCream } from "./IceCream"
import { useInterval } from 'usehooks-ts'
import usePrevious from "../hooks/usePrevious"
import { Entity } from "aframe"
import * as THREE from 'three'
import { sphericalCoordinateRandom } from "../utilities/sphericalCoordinateRandom"
import range from 'lodash/range'
import { sphericalCoordinate } from "../utilities/sphericalCoordinate"
import { randomInt } from "../utilities/randomInt"
import useSound from "../hooks/useSound"

interface Props {
    isActive: boolean
    index: number
    handleRemove: (index: number) => void
    handleIndicatorShow: (index: number, direction: string) => void
    handleIndicatorHide: (index: number) => void
    addPosition: (position: THREE.Vector3) => void
    removePosition: (position: THREE.Vector3) => void
    positions: THREE.Vector3[]
}

interface Item {
    id: number
    enabled: boolean,
    flavour: string
}

type Action = {
    type: 'setItems' | 'itemEnable' | 'itemDisable',
    payload?: any
}

const icecreams = [
    'banana',
    'bluesherbet',
    'caramel',
    'chocolate',
    'greentea',
    'strawberry',
]

const reducer = (state: Item[], action: Action) => {
    if (action.type === 'setItems') {
        return action.payload
    } else if (action.type === 'itemEnable') {
        const stateNew: Item[] = JSON.parse(JSON.stringify(state))
        const itemDisabled = stateNew.find((item) => {
            return item.enabled === false
        })

        const itemsNew = stateNew.map((item) => {
            if (item.id === itemDisabled?.id) {
                item.enabled = true
            }

            return item
        })

        return itemsNew
    } else if (action.type === 'itemDisable') {
        const stateNew: Item[] = JSON.parse(JSON.stringify(state))
        const itemsNew = stateNew.map((item) => {
            if (item.id === action.payload) {
                item.enabled = false
            }

            return item
        })

        return itemsNew
    } else {
        return state
    }
}

const initialState: Item[] = []

const getPositionRandom = () => {
    // From left (270) to right (90)
    const fiRandom = randomInt(0, 360, range(90, 360 - 90))
    // On ground
    const thetaRandom = randomInt(90, 90, [])
    // 8th Wall scales everything relative to camera height, which is 1.6, so need to acount for this in the radius or things seems very far away
    const rRandom = randomInt(5, 10, [])

    const coordinate = sphericalCoordinate(fiRandom, thetaRandom, rRandom)

    const vector = new THREE.Vector3(coordinate.x, coordinate.y, coordinate.z);

    return vector
}

export const Portal = ({
    isActive,
    index,
    handleRemove,
    positions,
    addPosition,
    removePosition,
    handleIndicatorShow,
    handleIndicatorHide,
}: Props) => {
    const [portalPosition, setPortalPosition] = useState<THREE.Vector3 | null>(null)
    const { onPortalEnter, onPortalExit } = useSound()

    useEffect(() => {
        if (isActive) {
            while (true) {
                const positionRandom = getPositionRandom()
                const positionOverlap = positions.some((position) => {
                    const distance = position.distanceTo(positionRandom)
                    if (distance < 5) {
                        return true
                    } else {
                        return false
                    }
                })

                if (!positionOverlap) {
                    setPortalPosition(positionRandom)
                    addPosition(positionRandom)
                    break;
                }
            }
        }

        return () => {
            if (portalPosition) {
                removePosition(portalPosition)
            }
        }
    }, [isActive])

    // Spawning

    const refRoot = useRef<Entity>(null)
    // Counter
    const [count, setCount] = useState<number>(0)
    // Counter Previous
    const countPrevious = usePrevious<number>(count)
    // Counter Max
    const [countMax, setCountMax] = useState<number>(3)
    // Items
    const [items, setItems] = useReducer(reducer, initialState)
    // Dynamic delay
    const [delay, setDelay] = useState<number>(1000)
    // ON/OFF
    const [isPlaying, setPlaying] = useState<boolean>(false)

    useInterval(
        () => {
            // Increase count
            setCount(count + 1)
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    useEffect(() => {
        setItems({
            type: 'setItems', payload: [...Array(countMax).keys()].map((item) => {
                return {
                    id: item,
                    enabled: false,
                    flavour: icecreams[Math.floor(Math.random() * icecreams.length)]
                }
            })
        })
    }, [])

    useEffect(() => {
        if (isPlaying) {
            if (count === countMax - 1) {
                setPlaying(false)
            } else {
                // setItems({
                //     type: 'itemEnable'
                // })
            }

            // setItems([...Array(count).keys()])
        } else {
            if (count === 0 && count < countPrevious!) {
                handleRemovePortal()
            }
        }
    }, [count, isPlaying, countPrevious])

    useEffect(() => {
        if (isActive && portalPosition) {
            setTimeout(() => {
                setPlaying(true)
            }, 1000)
        }
    }, [isActive, portalPosition])

    const handleRemoveIceCream = (index: number) => {
        // Decrease count
        setCount((count) => {
            return count - 1
        })

        // setItems({
        //     type: 'itemDisable',
        //     payload: index
        // })

        // Remove item
        // setItems((items) => {
        //     const itemsFiltered = items.filter((item) => {
        //         return item !== index
        //     })

        //     return itemsFiltered
        // })
    }

    // Animation

    const refModel = useRef<Entity>(null)

    useEffect(() => {
        const model = refModel.current

        const handleModelLoad = () => {
            model?.removeEventListener('model-loaded', handleModelLoad)

            setTimeout(() => {
                model?.setAttribute('visible', 'false')
            }, 0)
        }

        model?.addEventListener('model-loaded', handleModelLoad)
    }, [])

    useLayoutEffect(() => {
        if (isActive) {
            const model = refModel.current

            const handleAnimationFinished = () => {
                model?.removeEventListener('animation-finished', handleAnimationFinished)

                model?.setAttribute('animation-mixer', 'clip: idle_120FPS; loop: repeat; crossFadeDuration: 0;')
            }

            model?.addEventListener('animation-finished', handleAnimationFinished)
            model?.setAttribute('visible', 'true')
            model?.setAttribute('animation-mixer', `clip: open_120FPS; loop: once; crossFadeDuration: 0;`)
            onPortalEnter()
        }
    }, [isActive])

    const handleRemovePortal = () => {
        const model = refModel.current

        // const handleAnimationLoop = () => {
        //     model?.removeEventListener('animation-loop', handleAnimationLoop)

        model?.setAttribute('animation-mixer', `clip: close_120fsp; loop: once; crossFadeDuration: 0.2; `)

        const handleAnimationFinished = () => {
            model?.removeEventListener('animation-finished', handleAnimationFinished)

            model?.setAttribute('visible', 'false')
            handleRemove(index)

            if (portalPosition) {
                removePosition(portalPosition)
            }
        }

        model?.addEventListener('animation-finished', handleAnimationFinished)
        // }

        // model?.setAttribute('animation-mixer', 'clip: idle_120FPS; loop: repeat; timeScale: 2')

        // model?.addEventListener('animation-loop', handleAnimationLoop)
    }

    const [isTrackerShow, setIsTrackerShow] = useState<'left' | 'right' | boolean>(false)

    const handleTrackerShow = (event: any) => {
        setIsTrackerShow(event.detail.direction)
    }

    const handleTrackerHide = () => {
        setIsTrackerShow(false)
    }

    useEffect(() => {
        refRoot.current?.addEventListener('show', handleTrackerShow)
        refRoot.current?.addEventListener('hide', handleTrackerHide)

        return () => {
            refRoot.current?.removeEventListener('show', handleTrackerShow)
            refRoot.current?.removeEventListener('hide', handleTrackerHide)
        }
    }, [])

    useEffect(() => {
        if (typeof isTrackerShow === 'string') {
            handleIndicatorShow(index, isTrackerShow)
        } else {
            handleIndicatorHide(index)
        }
    }, [isTrackerShow])

    return (
        <a-entity
            ref={refRoot}
            position={`${portalPosition?.x} ${portalPosition?.y} ${portalPosition?.z}`}
            // position={`-0 0 -4`}
            class="portal"
        >
            <a-entity
                ref={refModel}
                scale="0.1 0.1 0.1"
                holdout="name: holdOut_geo;"
                visible="false"
                clone="target: #model-portal"
            // tracker
            />
            {/* <a-plane height="3" width="3" rotation="-90 0 0" material="color: red" /> */}

            {items.map((item: Item) => {
                return (
                    <IceCream key={item.id} index={item.id} handleRemove={handleRemoveIceCream} isActive={isPlaying && item.id <= count} flavour={item.flavour} />
                )
            })}
        </a-entity>
    )
}
