import React from "react";

function GaugeTimer(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="163"
            height="57"
            fill="none"
            viewBox="0 0 163 57"
            {...props}
        >
            <path
                fill="#282800"
                fillOpacity="0.6"
                fillRule="evenodd"
                d="M11.5 8.986h100l40 40h-100l-40-40z"
                clipRule="evenodd"
            ></path>
            <g filter="url(#filter0_dd_1_718)">
                <mask
                    id="path-2-outside-1_1_718"
                    width="150"
                    height="44"
                    x="6.5"
                    y="6.986"
                    fill="#000"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M6.5 6.986H156.5V50.986H6.5z"></path>
                    <path
                        fillRule="evenodd"
                        d="M11.5 8.986h100l40 40h-100l-40-40z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#FEFA00"
                    d="M111.5 8.986l1.414-1.414-.586-.586h-.828v2zm-100 0v-2H6.672l3.414 3.414L11.5 8.986zm140 40v2h4.828l-3.414-3.414-1.414 1.414zm-100 0l-1.414 1.415.586.585h.828v-2zm60-42h-100v4h100v-4zm41.414 40.586l-40-40-2.828 2.828 40 40 2.828-2.828zM51.5 50.986h100v-4h-100v4zM10.086 10.401l40 40 2.828-2.829-40-40-2.828 2.828z"
                    mask="url(#path-2-outside-1_1_718)"
                ></path>
            </g>
            <g filter="url(#filter1_dd_1_718)">
                <path
                    fill="#FEFA00"
                    fillRule="evenodd"
                    d="M21.5 12.986h88l32 32h-88l-32-32z"
                    clipRule="evenodd"
                ></path>
            </g>
            <g filter="url(#filter2_d_1_718)">
                <path
                    fill="#fff"
                    d="M67.286 19.658h2.8v19.164h-2.728V22.99l-.605.646-1.64-1.65 2.173-2.328zm7.793 16.416v2.738H72.36v-2.738h2.718zm0-5.116v2.737H72.36v-2.738h2.718zm5.054 5.137h5.343v2.727h-8.121v-5.865c.02-1.982 1.12-3.637 3.301-4.963 1.258-.82 1.897-1.753 1.918-2.8v-1.588c.02-.787-.37-1.18-1.17-1.18h-.102c-.759 0-1.148.349-1.169 1.046v1.477h-2.768V22.58c0-1.934.97-2.909 2.912-2.922h2.163c1.942.013 2.913.987 2.913 2.922v3.26c-.062 1.97-1.156 3.61-3.282 4.923-1.271.936-1.917 1.893-1.938 2.87v2.462zm7.485-16.437h2.728v9.32h1.681v-3.701h2.728v3.701h1.313v2.728h-1.313v7.116h-2.727v-7.116h-4.41V19.658z"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_dd_1_718"
                    width="161.657"
                    height="56"
                    x="0.672"
                    y="0.986"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_718"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_1_718"
                        result="effect2_dropShadow_1_718"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1_718"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_1_718"
                    width="132"
                    height="44"
                    x="15.5"
                    y="6.986"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_718"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_1_718"
                        result="effect2_dropShadow_1_718"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1_718"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter2_d_1_718"
                    width="46.955"
                    height="35.165"
                    x="55.112"
                    y="13.658"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dx="-2" dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="4"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.156863 0 0 0 0 0 0 0 0 0.6 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_718"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1_718"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default GaugeTimer;