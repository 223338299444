import ModalCloseIcon from "../../assets/icons/ModalCloseIcon";
import { useModalContext } from "../../context/ModalContext";
import Button from "./Button";
import Paragraph from "./Paragraph";

interface ErrorModalProps {
  msg: string
}

const ErrorModal = ({ msg }: ErrorModalProps) => {
  const { onMondalClose } = useModalContext()

  const handleClose = () => {
    onMondalClose()
  }

  return (
    <div className="max-w-[320px]">
      <div className="flex justify-end mb-4">
        <button onClick={handleClose}><ModalCloseIcon /></button>
      </div>
      <div className=" border-4 border-black rounded bg-white relative">
        <div className="dots-bg dots-bg--orange bg-brand-yellow p-6 border-b-4 border-black flex items-center justify-center">
          <Paragraph as="span" className="font-semibold text-xl text-black-shadow font-comic inline-block leading-none">
            Error!
          </Paragraph>
        </div>
        <div className="p-6 pb-10 text-center space-y-4">

          <Paragraph as="p" className="font-bold">
            {msg}
          </Paragraph>

          <div className="absolute -bottom-6 left-[50%] translate-x-[-50%] space-y-2 w-full flex flex-col max-w-[150px]">
            <Button as="button" variation="dark" text="Close" onClick={handleClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
