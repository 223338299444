import classNames from "classnames";
import React, { useEffect, useState } from "react";

interface ParagraphProps {
  as?: "p" | "span" | "div";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  className?: string;
  isTranslatable?: boolean;
  [propName: string]: any;
}

const Paragraph = React.forwardRef<any, ParagraphProps>((props, ref) => {
  const {
    className = "",
    as = "p",
    size = "md",
    isTranslatable = false,
    children,
    ...otherProps
  } = props;

  const [pClassName, setPClassName] = useState("");


  useEffect(() => {
    let textStyle = "";
    if (size === "2xl") textStyle = "text-2xl leading-32";
    else if (size === "xl") textStyle = "text-xl leading-30";
    else if (size === "lg") textStyle = "text-lg leading-28";
    else if (size === "md") textStyle = "text-md leading-24";
    else if (size === "sm") textStyle = "text-sm leading-20";
    else if (size === "xs") textStyle = "text-xs leading-18";

    setPClassName(classNames(textStyle, className));
  }, []);

  if (as === "span") {
    return (
      <span ref={ref} className={pClassName} {...otherProps}>
        {children}
      </span>
    );
  } else if (as === "div") {
    return (
      <div ref={ref} className={pClassName} {...otherProps}>
        {children}
      </div>
    );
  } else {
    return (
      <p ref={ref} className={pClassName} {...otherProps}>
        {children}
      </p>
    );
  }
});

export default Paragraph;
