import ModalCloseIcon from "../../assets/icons/ModalCloseIcon";
import PreviousArrow from "../../assets/icons/PreviousArrow";
import { useModalContext } from "../../context/ModalContext";
import Button from "./Button";
import Paragraph from "./Paragraph";

interface ReturnModalProps { }

const ReturnModal = ({ }: ReturnModalProps) => {
    const { onMondalClose } = useModalContext()

    const handleClose = () => {
        onMondalClose()
    }

    return (
        <div className="max-w-[320px]">
            <div className="flex justify-end mb-4">
                <button onClick={handleClose}><ModalCloseIcon /></button>
            </div>
            <div className=" border-4 border-black rounded bg-white relative">
                <div className="dots-bg dots-bg--orange bg-brand-yellow p-6 border-b-4 border-black flex items-center justify-center">
                    <Paragraph as="span" className="font-semibold text-xl text-black-shadow font-comic inline-block leading-none">
                        Return Home
                    </Paragraph>
                </div>
                <div className="p-6 pb-10 text-center space-y-4">

                    <Paragraph as="p">
                        Are you sure you’d like to exit the game?
                    </Paragraph>

                    <div className="absolute left-[50%] translate-x-[-50%] w-max">
                        <div className="flex justify-between w-full space-x-3">
                            <Button
                                variation="dark"
                                as="button"
                                icon={
                                    <PreviousArrow className="my-[5px] w-[35px] scale-90" />
                                }
                                iconText
                                onClick={handleClose}
                            />
                            <Button
                                variation="light"
                                as="a"
                                url="/"
                                text="Take me home"
                                isWide
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReturnModal;
