import { useContext, useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Reload.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import { gameContext, useGameState } from './GameState'
import ReloadIcon from '../assets/icons/ReloadIcon'
import useSound from '../hooks/useSound'
import ReloadIconIndicator from '../assets/icons/ReloadIconIndicator'

interface Props { }

export const Reload = ({ }: Props) => {
    const gameState = useContext(gameContext)
    const { state, dispatch } = useGameState()
    const bullets = state.bullets
    const isBulletsEmpty = 10 - bullets === 10

    const { onReload } = useSound()

    const handleClick = () => {
        onReload()
        dispatch({ type: 'setReload' })
    }

    return (
        <>
            <button className={styles.button} onClick={handleClick}>
                <ReloadIcon />
                {isBulletsEmpty && <ReloadIconIndicator className={styles.indicator} />}
            </button>
        </>
    )
}