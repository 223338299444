import { RefObject, useContext, useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './CountDown.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import { gameContext, useGameState } from './GameState'
import ReloadIcon from '../assets/icons/ReloadIcon'
import useSound from '../hooks/useSound'
import CountDownSvg from '../assets/svg/CountDown'
import clsx from 'clsx'
import { useTimer } from 'react-timer-hook'
import { Transition } from 'react-transition-group'
import gsap from 'gsap'

interface Props { }

const expiryTimestamp = new Date();
const secondsTotal = 3
expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + secondsTotal)

export const CountDown = ({ }: Props) => {
    // Timer
    const {
        seconds,
        restart
    } = useTimer({
        expiryTimestamp,
        autoStart: false,
        onExpire: () => {
            handleExpiry()
        },
    });


    const { dispatch } = useGameState()
    const handleExpiry = () => {
        dispatch({
            type: 'setStarted'
        })
        setIsOpen(false)
    }

    useEffect(() => {
        const expiryTimestamp = new Date();
        const secondsTotal = 3
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + secondsTotal)

        setIsOpen(true)
        restart(expiryTimestamp)
    }, [])

    // Animation
    const [isOpen, setIsOpen] = useState(false)
    const refRoot = useRef<HTMLDivElement>(null)

    const handleEnter = (nodeRef: RefObject<HTMLDivElement>) => {
        gsap.fromTo(
            nodeRef.current,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
        )
    }

    const handleExit = (nodeRef: RefObject<HTMLDivElement>) => {
        gsap.fromTo(
            nodeRef.current,
            {
                autoAlpha: 1,
            },
            {
                autoAlpha: 0,
            },
        )
    }

    return (
        <Transition
            in={isOpen}
            timeout={1000}
            mountOnEnter
            unmountOnExit
            nodeRef={refRoot}
            onEnter={() => handleEnter(refRoot)}
            onExit={() => handleExit(refRoot)}
        >
            <div ref={refRoot} className='fixed z-10 top-[120px] left-1/2 -translate-x-1/2 grid grid-cols-1 grid-rows-1 place-content-center'>
                <CountDownSvg className="col-span-full row-span-full" />
                <div className={clsx(styles.text, 'grid place-content-center col-span-full row-span-full font-pawlskinny translate-y-[5%]')}>{seconds === 0 ? 'GO' : seconds}</div>
            </div>
        </Transition>
    )
}