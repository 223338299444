import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react'

interface Props {
    children?: ReactNode
}

type State = {
    started: boolean,
    points: number,
    lastPoints: number,
    scoops: number,
    time: number,
    bullets: number,
    combo: number,
    multiplier: number,
    lastHit: Date | null,
}

type Action = {
    type: 'setPoints' | 'setTime' | 'setBullets' | 'setCombo' | 'setReload' | 'setStarted',
    payload?: any
}

const reducer = (state: State, action: Action) => {
    if (action.type === 'setPoints') {
        let combo = state.combo
        let multiplier = state.multiplier
        const lastHit = new Date()
        const points = action?.payload || 1

        if (state.lastHit && lastHit.getSeconds() - state.lastHit.getSeconds() < 6) {
            combo = combo + 1
        } else {
            combo = 0
        }

        if (combo >= 20) {
            multiplier = 3
        } else if (combo >= 10) {
            multiplier = 2
        } else {
            multiplier = 1
        }

        return {
            ...state,
            points: state.points + (points * multiplier),
            lastPoints: points * multiplier,
            scoops: state.scoops + 1,
            lastHit: new Date(),
            combo,
            multiplier,
        }
    } else if (action.type === 'setBullets') {
        return {
            ...state,
            bullets: state.bullets - 1
        }
    } else if (action.type === 'setReload') {
        return {
            ...state,
            bullets: 10
        }
    } else if (action.type === 'setStarted') {
        return {
            ...state,
            started: true
        }
    }
    return state
}

const initialState: State = {
    started: false,
    points: 0,
    lastPoints: 0,
    scoops: 0,
    time: 0,
    bullets: 10,
    combo: 0,
    multiplier: 1,
    lastHit: null,
}

export const gameContext = createContext<{ state: State, dispatch: Dispatch<Action> }>({ state: initialState, dispatch: () => { } });

export const GameState = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState)



    return (
        <gameContext.Provider value={{ state, dispatch }}>
            {children}
        </gameContext.Provider>
    )
}

export const useGameState = () => useContext(gameContext);