import { Entity, Scene } from "aframe"
import gsap from "gsap"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import * as THREE from 'three'
import { gameContext } from "./GameState"
import useSound from "../hooks/useSound"

interface Props {
    isActive: boolean
    index: number
    handleRemove: (index: number) => void
    flavour: string
}

export const IceCream = ({ isActive, index, handleRemove, flavour }: Props) => {
    const refRoot = useRef<Entity>(null)
    const refModels = useRef<Entity>(null)
    const refModel = useRef<Entity>(null)
    const refModelDie = useRef<Entity>(null)
    const refGlitch = useRef<Entity>(null)
    const refGlitch1 = useRef<Entity>(null)
    const refGlitch2 = useRef<Entity>(null)
    const refGlitch3 = useRef<Entity>(null)
    const refGlitch4 = useRef<Entity>(null)
    const refGlitch5 = useRef<Entity>(null)
    const refGlitch6 = useRef<Entity>(null)
    const [isDead, setIsDead] = useState(true)
    const { onIceCreamEnter, onIceCreamExit } = useSound()

    useLayoutEffect(() => {
        setIsDead(false)

        if (isActive) {
            // Creat context
            let context: gsap.Context
            // Get entities
            const root = refRoot.current!
            const model = refModel.current!
            const modelDie = refModelDie.current!
            const models = refModels.current!
            // Animate entity
            context = gsap.context(() => {
                const timeline = gsap.timeline({
                    // repeat: -1, 
                    onStart: () => {
                        root?.setAttribute('visible', 'true')
                        model?.setAttribute('visible', 'true')
                        modelDie?.setAttribute('visible', 'false')
                        modelDie?.setAttribute('scale', '0.1 0.1 0.1')
                        onIceCreamEnter()
                    },
                    onComplete: () => {
                        root?.setAttribute('visible', 'false')
                        model?.setAttribute('visible', 'false')
                        modelDie?.setAttribute('visible', 'false')
                        setIsDead(false)
                        handleRemove(index)
                    }
                })

                timeline
                    .fromTo(
                        models.object3D.scale,
                        {
                            x: 0,
                            y: 0,
                            z: 0
                        },
                        {
                            x: 1,
                            y: 1,
                            z: 1,
                            duration: 1,
                        },
                        0
                    )
                    .fromTo(
                        models.object3D.rotation,
                        {
                            x: 0,
                            y: 0,
                            z: 0
                        },
                        {
                            x: THREE.MathUtils.degToRad(360),
                            y: THREE.MathUtils.degToRad(360),
                            z: 0,
                            duration: 3,
                            ease: 'none'
                        },
                        0
                    )
                    .fromTo(
                        root.object3D.position,
                        {
                            x: 0,
                            y: -0.5,
                            z: 0
                        },
                        {
                            x: THREE.MathUtils.randFloat(-3, 3),
                            y: 3,
                            z: THREE.MathUtils.randFloat(-3, 3),
                            duration: 3,
                        },
                        0
                    )
                    .fromTo(
                        models.object3D.scale,
                        {
                            x: 1,
                            y: 1,
                            z: 1
                        },
                        {
                            x: 0,
                            y: 0,
                            z: 0,
                            ease: 'elastic.in(2, 1)',
                            duration: 1,
                        },
                        '-=1'
                    )
            })
        }
    }, [isActive])

    useEffect(() => {
        const root = refRoot.current
        const model = refModel.current
        const modelDie = refModelDie.current

        const handleModelLoad = () => {
            model?.removeEventListener('model-loaded', handleModelLoad)

            setTimeout(() => {
                root?.setAttribute('visible', 'false')
                model?.setAttribute('visible', 'false')
                modelDie?.setAttribute('visible', 'false')
            }, 0)
        }

        model?.addEventListener('model-loaded', handleModelLoad)
    }, [])

    const gameState = useContext(gameContext)
    useLayoutEffect(() => {
        // Get entity
        const model = refModel.current
        const modelDie = refModelDie.current!
        const models = refModels.current!
        const glitch = refGlitch.current!
        const glitch1 = refGlitch1.current!
        const glitch2 = refGlitch2.current!
        const glitch3 = refGlitch3.current!
        const glitch4 = refGlitch4.current!
        const glitch5 = refGlitch5.current!
        const glitch6 = refGlitch6.current!

        const handleDie = () => {
            setIsDead(true)

            if (!isDead) {
                gameState?.dispatch({ type: 'setPoints' })
                model?.setAttribute('visible', 'false')
                modelDie?.setAttribute('visible', 'true')
                glitch?.setAttribute('visible', 'true')

                const timelineGlitch = gsap.timeline()

                timelineGlitch
                    .set(
                        [
                            glitch1?.object3D,
                            glitch2?.object3D,
                            glitch3?.object3D,
                            glitch4?.object3D,
                            glitch4?.object3D,
                            glitch5?.object3D,
                            glitch6?.object3D
                        ],
                        {
                            visible: false,
                        },
                    )
                    .set(
                        glitch1?.object3D,
                        {
                            visible: true,
                        },
                    )
                    .set(
                        glitch1?.object3D,
                        {
                            visible: false,
                            delay: 0.1
                        },
                    )
                    .set(
                        glitch2?.object3D,
                        {
                            visible: true,
                        },
                    )
                    .set(
                        glitch2?.object3D,
                        {
                            visible: false,
                            delay: 0.4
                        },
                    )
                    .set(
                        glitch3?.object3D,
                        {
                            visible: true,
                        },
                    )
                    .set(
                        glitch3?.object3D,
                        {
                            visible: false,
                            delay: 0.05
                        },
                    )
                    .set(
                        glitch4?.object3D,
                        {
                            visible: true,
                        },
                    )
                    .set(
                        glitch4?.object3D,
                        {
                            visible: false,
                            delay: 0.05
                        },
                    )
                    .set(
                        glitch5?.object3D,
                        {
                            visible: true,
                        },
                    )
                    .set(
                        glitch5?.object3D,
                        {
                            visible: false,
                            delay: 0.05
                        },
                    )
                    .set(
                        glitch6?.object3D,
                        {
                            visible: true,
                        },
                    )
                    .set(
                        glitch6?.object3D,
                        {
                            visible: false,
                            delay: 0.5
                        },
                    )

                const timeline = gsap.timeline({})

                timeline
                    .fromTo(
                        glitch.object3D.scale,
                        {
                            x: 0,
                            y: 0,
                            z: 0
                        },
                        {
                            x: 1.5,
                            y: 1.5,
                            z: 1.5,
                            duration: 1,
                            ease: 'elastic.out(2, 1)',
                        },
                        0
                    )
                    .add(
                        timelineGlitch,
                        0
                    )
                    .addLabel(
                        'afterGlitch',
                        '-=0.75'
                    )
                    .to(
                        modelDie.object3D.scale,
                        {
                            x: 0,
                            y: 0,
                            z: 0,
                            ease: 'elastic.in(2, 1)',
                            duration: 1,
                        },
                        `afterGlitch-=1`
                    )
                    .fromTo(
                        glitch.object3D.scale,
                        {
                            x: 1.5,
                            y: 1.5,
                            z: 1.5,
                        },
                        {
                            x: 0,
                            y: 0,
                            z: 0,
                            duration: 1,
                            ease: 'elastic.in(2, 1)',
                        },
                        `afterGlitch-=1`
                    )
            }
        }

        models.addEventListener('die', handleDie);

        return () => {
            // context?.revert()
            models.removeEventListener('die', handleDie);
        }
    }, [isDead])

    return (
        <a-entity
            ref={refRoot}
            visible="false"
        >
            <a-entity
                ref={refModels}
            >
                <a-entity
                    ref={refModel}
                    target="healthPoints: 1; static: false;"
                    scale="0.1 0.1 0.1"
                    position="0 -0.75 0"
                    className="cantap"
                    clone={`target: #model-icecream-${flavour}`}
                />
                <a-entity
                    ref={refModelDie}
                    position-reset="name: WebWrapped_Cone;"
                    scale="0.1 0.1 0.1"
                    clone="target: #model-wrap"
                // position="0 -0.75 0"
                />
            </a-entity>
            <a-plane
                ref={refGlitch}
                position="0 0 0.5"
                material="transparent: true; opacity: 0;"
                look-at="#camera"
                visible="false"
            >
                <a-image
                    ref={refGlitch1}
                    src="#glitch-1"
                    visible="false"
                />
                <a-image
                    ref={refGlitch2}
                    src="#glitch-2"
                    visible="false"
                />
                <a-image
                    ref={refGlitch3}
                    src="#glitch-3"
                    visible="false"
                />
                <a-image
                    ref={refGlitch4}
                    src="#glitch-4"
                    visible="false"
                />
                <a-image
                    ref={refGlitch5}
                    src="#glitch-5"
                    visible="false"
                />
                <a-image
                    ref={refGlitch6}
                    src="#glitch-6"
                    visible="false"
                />
            </a-plane>
        </a-entity>
    )
}
