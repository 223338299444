import { useEffect, useRef } from "react";
import gsap from 'gsap';

function ReloadIconIndicator(props: any) {
    const refSvg = useRef(null)

    useEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({
                repeat: -1
            })

            timeline.timeScale(0.5)

            timeline
                .fromTo(
                    '.circle1, .circle2',
                    {
                        scale: 0,
                        autoAlpha: 0,
                        transformOrigin: '50% 50%',
                    },
                    {
                        scale: 1,
                        autoAlpha: 1,
                        ease: 'elastic.out(1.5, 1)',
                    },
                    '+=0'
                )
                .fromTo(
                    '.circle3, .circle4',
                    {
                        scale: 84 / 140,
                        autoAlpha: 1,
                        transformOrigin: '50% 50%',
                    },
                    {
                        scale: 1,
                        autoAlpha: 0,
                        // // transformOrigin: '100% 100%',
                        stagger: 0.2
                    },
                    '-=0.5'
                )
                .fromTo(
                    '.circle1, .circle2',
                    {
                        autoAlpha: 1,
                    },
                    {
                        autoAlpha: 0,
                    },
                    '+=0'
                )
        }, refSvg)

        return () => ctx.revert()
    }, [])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="150"
            fill="none"
            viewBox="0 0 150 150"
            ref={refSvg}
            {...props}
        >
            <g className="circle2" filter="url(#filter0_dd_1950_40727)">
                <circle
                    cx="75"
                    cy="75"
                    r="41.25"
                    stroke="#FEFA00"
                    strokeWidth="1.5"
                ></circle>
            </g>
            <g className="circle3" filter="url(#filter2_dd_1950_40727)">
                <path
                    stroke="#FEFA00"
                    strokeWidth="1.5"
                    d="M144.251 75c0 38.246-31.004 69.25-69.25 69.25-38.246 0-69.25-31.004-69.25-69.25 0-38.246 31.004-69.25 69.25-69.25 38.246 0 69.25 31.004 69.25 69.25z"
                ></path>
            </g>
            <g className="circle4" filter="url(#filter2_dd_1950_40727)">
                <path
                    stroke="#FEFA00"
                    strokeWidth="1.5"
                    d="M144.251 75c0 38.246-31.004 69.25-69.25 69.25-38.246 0-69.25-31.004-69.25-69.25 0-38.246 31.004-69.25 69.25-69.25 38.246 0 69.25 31.004 69.25 69.25z"
                ></path>
            </g>
            <circle className="circle1" cx="75" cy="75" r="42" fill="#F71B1B"></circle>
            <defs>
                <filter
                    id="filter0_dd_1950_40727"
                    width="92.4"
                    height="92.4"
                    x="28.8"
                    y="28.8"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="2.1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1950_40727"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.05"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_1950_40727"
                        result="effect2_dropShadow_1950_40727"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1950_40727"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_1950_40727"
                    width="120.4"
                    height="120.4"
                    x="14.8"
                    y="14.8"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="2.1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1950_40727"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.05"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_1950_40727"
                        result="effect2_dropShadow_1950_40727"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1950_40727"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter2_dd_1950_40727"
                    width="148.4"
                    height="148.4"
                    x="0.801"
                    y="0.8"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="2.1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1950_40727"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.05"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_1950_40727"
                        result="effect2_dropShadow_1950_40727"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1950_40727"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default ReloadIconIndicator;