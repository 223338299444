import { Link } from 'react-router-dom'
import { Results as Score } from '../components/Results'

const Results = () => (
    <>
        <Score />
    </>
)

export { Results }
