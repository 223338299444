import { ISoundSource } from "../types/sound";
// import { AEData } from "./ae";
// import { AUData } from "./au";

// export const config = {
//   AU: AUData,
//   AE: AEData,
// };

export const footNote = (
  <>
    &copy; & &trade; {new Date().getFullYear()} MARVEL. &copy; SPAI. All Rights
    Reserved. Check the classification. T&Cs Apply.
  </>
);

export const soundSource: ISoundSource = {
  pageBackground: "./audio/Microsite-Pages.mp3",

  shootEmpty: "./audio/Gameplay Button - Web shots firing empty C_v2.mp3",
  shootLow: "./audio/Gameplay Button - Web shots firing low_v2.mp3",
  shoot: "./audio/Gameplay Button - Web Shots firing_v2.mp3",
  reload: "./audio/Gameplay Button - Web shots reload interaction B_v2.mp3",
  impact: "./audio/Gameplay Feedback - Web impact A.mp3",
  spotPortal: "./audio/Gameplay Feedback - Villain (Spot) Portal Sequence.mp3",
  spotDie: "./audio/Gameplay Character - Spot getting hurt - Exertion E.mp3",
  iceCreamEnter: "./audio/Gameplay Feedback - Ice cream entry_B3.mp3",
  iceCreamExit: "./audio/Gameplay Feedback - Ice cream exit - Glitch Fade A_v2.mp3",
  portalEnter: "./audio/Gameplay Feedback - Portal Opening.mp3",
  portalExit: "./audio/Gameplay Feedback - Portal Closing.mp3",
  timer0: "./audio/Gameplay Feedback - Time finished 0s remaining B.mp3",
  timer10: "./audio/Gameplay Feedback - Time warning 10s remaining.mp3",
};