import { createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const GoogleTagManagerContext = createContext(null);

export const GoogleTagManagerProvider = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            pagePath: location.pathname,
            pageTitle: document.title,
        });
    }, [location]);

    return (
        <GoogleTagManagerContext.Provider
            value={null}
        >
            {children}
        </GoogleTagManagerContext.Provider>
    );
};

export const useGoogleTagManagerContext = () => useContext(GoogleTagManagerContext);
