// @ts-expect-error
import styles from './Notifications.module.scss'
import { useNotifcations } from './NotificationsProvider'
import Notification from './parts/Notification'
import { Transition, TransitionGroup } from 'react-transition-group'
import { RefObject, createRef, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { useGameState } from './GameState'

interface Props { }

export const Notifications = ({ }: Props) => {
    const { state } = useNotifcations()

    const stateFiltered = state.filter((item, index) => {
        return index === state.length - 1
    })

    const handleEnter = (nodeRef: RefObject<HTMLLIElement>) => {
        const timeline = gsap.timeline({})

        timeline
            .fromTo(
                nodeRef.current,
                {
                    autoAlpha: 0,
                    y: '100%',
                    scale: 0.5,
                },
                {
                    autoAlpha: 1,
                    y: '0%',
                    scale: 1,
                },
            )
            .to(
                nodeRef.current,
                {
                    autoAlpha: 0,
                    y: '-100%',
                    scale: 0.5,
                    delay: 2
                },
            )
    }

    const handleExit = (nodeRef: RefObject<HTMLLIElement>) => {
        gsap.fromTo(
            nodeRef.current,
            {
                autoAlpha: 1,
                y: '0%',
                scale: 1,
            },
            {
                autoAlpha: 0,
                y: '-100%',
                scale: 0.5,
            },
        )
    }

    // Dispatch

    const { state: gameState } = useGameState()
    const { dispatch } = useNotifcations()

    useEffect(() => {
        if (gameState.lastPoints) {
            dispatch({
                type: 'addNotification',
                payload: {
                    id: Date.now(),
                    prefix: '+',
                    text: String(gameState.lastPoints),
                    suffix: 'pts',
                    status: 'normal'
                }
            })
        }
    }, [gameState.points, gameState.lastPoints])

    return (
        <ul className={styles.root}>
            <TransitionGroup>
                {stateFiltered.map((item, index) => {
                    const nodeRef = createRef<HTMLLIElement>()

                    return (
                        <Transition
                            key={item.id}
                            // in={index === state.length - 1}
                            timeout={1000}
                            onEnter={() => handleEnter(nodeRef)}
                            onExit={() => handleExit(nodeRef)}
                            nodeRef={nodeRef as RefObject<HTMLLIElement>}
                        >
                            <li
                                ref={nodeRef}
                            >
                                <Notification
                                    text={item.text}
                                    prefix={item.prefix}
                                    suffix={item.suffix}
                                    variant={item.status}
                                />
                            </li>
                        </Transition>
                    )
                })}
            </TransitionGroup>
        </ul>
    )
}