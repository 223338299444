import React from "react";

const ModalCloseIcon = (props: any) => {
  return (
    <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.9837 11.718C11.9837 11.718 18.5529 17.3205 22.4847 21.2039C25.6265 24.3071 30.2419 29.4314 30.2419 29.4314" stroke="#FF0067" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9131 30.7905C12.9131 30.7905 20.026 22.8539 24.9706 18.1563C27.3296 15.9153 31.1435 12.5604 31.1435 12.5604" stroke="#FF0067" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9837 11.718C11.9837 11.718 18.5529 17.3205 22.4847 21.2039C25.6265 24.3071 30.2419 29.4314 30.2419 29.4314" stroke="#1B09FF" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9131 30.7903C12.9131 30.7903 20.026 22.8536 24.9706 18.1561C27.3296 15.915 31.1435 12.5601 31.1435 12.5601" stroke="#1B09FF" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9837 11.718C11.9837 11.718 18.5529 17.3205 22.4847 21.2039C25.6265 24.3071 30.2419 29.4314 30.2419 29.4314" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.915 30.7903C12.915 30.7903 20.0279 22.8536 24.9726 18.1561C27.3315 15.915 31.1454 12.5601 31.1454 12.5601" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.0414 39.7591C31.9769 38.7143 41.9999 34.4755 42 21.3582C42 8.24092 33.0642 2.14629 22.0414 2.14629C11.0187 2.14629 0.981208 9.63399 2.08295 21.3582C3.1847 33.0825 12.1059 40.8039 22.0414 39.7591Z" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ModalCloseIcon;
