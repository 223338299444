import React from "react";

function ShootIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="122"
            height="123"
            fill="none"
            viewBox="0 0 122 123"
            {...props}
        >
            <circle
                cx="61"
                cy="61.687"
                r="60"
                fill="#282800"
                fillOpacity="0.4"
                stroke="#FEFA00"
                strokeWidth="2"
            ></circle>
        </svg>
    );
}

export default ShootIcon;