import React from "react";

const PreviousArrow = (props: any) => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.625 14.5137H4.375" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.25 6.63867L4.375 14.5137L12.25 22.3887" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default PreviousArrow;
