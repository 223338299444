AFRAME.registerComponent("tracker", {
	schema: {},

	//LIFECYCLE
	//----------------------------------
	init: function () {
		this.hidden = true
		this.camera = document.querySelector('#camera');
		this.debugTick = AFRAME.utils.throttle(this.updateMarkers, 1000, this);
	},//init
	tick: function () {
		this.debugTick();
		// this.updateMarkers();
	},//tick

	//UTILS
	//--------------------------------------
	getTHREECamera(cameraEntity) {
		//grab the first camera in the object (filters out groups)
		for (let child of cameraEntity.object3D.children) {
			if (child.type.indexOf("Camera") > -1) {
				return child;
			}
		}
	},//getTHREECamera
	updateMarkers: function () {
		if (!this.el.getAttribute('visible')) {
			this.hide();
			this.el.emit('hide')

			return
		}
		vector = this.getEntityVector()
		//gets x,y of tracked object on screen...
		trackedPosition = this.getEntityPosition2D(vector);
		//...and checks to see if it's visible
		insideViewport = this.checkInsideViewport(trackedPosition);

		//hide marker if tracked object is visible...
		if (insideViewport) {
			this.hide();
			this.el.emit('hide')
		}
		//...otherwise figure out where marker goes...
		else if (!insideViewport) {
			const direction = this.getEntityDirection(vector)

			this.show();
			this.el.emit('show', { direction })
		}

	},//updateMarkers
	checkInsideViewport: function ({ x, y }) {

		//returns true if the entity is within all bounds
		const boundsLeft = x > 0;
		const boundsBottom = y > 0;
		const boundsRight = x < window.innerWidth;
		const boundsTop = y < window.innerHeight;


		return boundsLeft && boundsRight && boundsBottom && boundsTop;
	},//checkInsideViewport
	getEntityVector() {
		const vector = new THREE.Vector3();
		const entityObj = this.el.object3D;

		entityObj.updateMatrixWorld();
		vector.setFromMatrixPosition(entityObj.matrixWorld);
		// entityObj.getWorldPosition(vector);

		return vector
	},//getEntityPosition
	getEntityPosition2D(vector) {
		const xCenter = window.innerWidth / 2;
		const yCenter = window.innerHeight / 2;
		const camera = this.getTHREECamera(this.camera);

		//if the scene's camera has loaded yet...
		if (camera) {

			//get entity position from camera projection...
			vector.project(camera);

			//transform vector to be relative to screen dimensions
			const x = (vector.x * xCenter) + xCenter;
			const y = (vector.y * yCenter) + yCenter;

			return {
				x, y
			};
		} else throw "Cannot get projection without camera";
	},//getEntityPosition
	getEntityDirection(vector) {
		return vector.x < 0 ? 'left' : 'right'
	},//getEntityPosition
	updatePosition({ x, y }) {

		//make sure the position can't extend beyond the bounds of the screen
		const leftClamp = x < 0 ? 0 : x;
		const bottomClamp = y < 0 ? 0 : y;
		const rightClamp = x > window.innerWidth ? window.innerWidth : x;
		const topClamp = y > window.innerHeight ? window.innerHeight : window.innerHeight - y; //invert y

		//apply position clamps if needed
		const position = {
			x: x < 0 ? leftClamp : rightClamp,
			y: y < 0 ? bottomClamp : topClamp
		};

		return position
	},//updatePosition
	show() {

		this.hidden = false;
	},//show
	hide() {

		this.hidden = true;
	}//hide
});//tracker