import { useSoundContext } from "../context/SoundContext";
import { soundSource } from "../data/constants";
import { Howl } from "howler";

const shootEmpty = new Howl({
  src: [soundSource.shootEmpty],
  autoplay: false,
});

const shootLow = new Howl({
  src: [soundSource.shootLow],
  autoplay: false,
});

const shoot = new Howl({
  src: [soundSource.shoot],
  autoplay: false,
});

const reload = new Howl({
  src: [soundSource.reload],
  autoplay: false,
});

const impact = new Howl({
  src: [soundSource.impact],
  autoplay: false,
});

const spotPortal = new Howl({
  src: [soundSource.spotPortal],
  autoplay: false,
});

const spotDie = new Howl({
  src: [soundSource.spotDie],
  autoplay: false,
});

const iceCreamEnter = new Howl({
  src: [soundSource.iceCreamEnter],
  autoplay: false,
  volume: 0.1,
});

const iceCreamExit = new Howl({
  src: [soundSource.iceCreamExit],
  autoplay: false,
  volume: 0.1,
});

const portalEnter = new Howl({
  src: [soundSource.portalEnter],
  autoplay: false,
  volume: 0.1,
});

const portalExit = new Howl({
  src: [soundSource.portalExit],
  autoplay: false,
  volume: 0.1,
});

const timer10 = new Howl({
  src: [soundSource.timer10],
  autoplay: false,
});

const timer0 = new Howl({
  src: [soundSource.timer0],
  autoplay: false,
});

function useSound() {
  const {
    isSoundPermitted
  } = useSoundContext()

  const handlePlay = (sound: Howl) => {
    if (isSoundPermitted) {
      if (sound.playing()) {
        sound.seek(0)
        sound.play()
      } else {
        sound.play();
      }
    }
  }

  const handleFadeIn = (sound: Howl) => {
    if (isSoundPermitted) {
      if (sound.playing()) {
        sound.seek(0)
        sound.play()
        sound.fade(0, 1, 1000)
      } else {
        sound.fade(0, 1, 1000)
      }
    }
  }

  const handleFadeOut = (sound: Howl) => {
    if (isSoundPermitted) {
      sound.fade(1, 0, 1000)
    }
  }

  const onShootEmpty = () => {
    handlePlay(shootEmpty!)
  }

  const onShootLow = () => {
    handlePlay(shootLow!)
  }

  const onShoot = () => {
    handlePlay(shoot!)
  }

  const onReload = () => {
    handlePlay(reload!)
  }

  const onImpact = () => {
    handlePlay(impact!)
  }

  const onSpotPortal = () => {
    handlePlay(spotPortal!)
  }

  const onSpotDie = () => {
    handlePlay(spotDie!)
  }

  const onIceCreamEnter = () => {
    handlePlay(iceCreamEnter!)
  }

  const onIceCreamExit = () => {
    handlePlay(iceCreamExit!)
  }

  const onPortalEnter = () => {
    handlePlay(portalEnter!)
  }

  const onPortalExit = () => {
    handlePlay(portalExit!)
  }

  const onTimer10 = () => {
    handlePlay(timer10!)
  }

  const onTimer0 = () => {
    handlePlay(timer0!)
  }

  return {
    onShootEmpty,
    onShootLow,
    onShoot,
    onReload,
    onImpact,
    onSpotPortal,
    onSpotDie,
    onIceCreamEnter,
    onIceCreamExit,
    onPortalEnter,
    onPortalExit,
    onTimer10,
    onTimer0,
  };
}

export default useSound;
