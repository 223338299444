AFRAME.registerComponent('clone', {
    schema: {
        target: { default: '', type: 'string' },
    },

    init: function () {
        const target = document.querySelector(this.data.target)
        const object3DTargetOriginal = target.getObject3D('mesh')

        const clone = (object3DTargetOriginal) => {
            let animations

            object3DTargetOriginal.traverse((node) => {
                if (node.animations.length) {
                    animations = node.animations

                    node.name = 'animations'
                }
            })

            const object3DTargetClone = object3DTargetOriginal.clone(true)

            object3DTargetClone.traverse((node) => {
                if (node.name === 'animations') {
                    node.animations = animations
                }
            })

            this.el.setObject3D('mesh', object3DTargetClone)

            this.el.emit('model-loaded', { format: 'gltf', model: object3DTargetClone });
        }

        const handleModelLoaded = (event) => {
            target.removeEventListener('model-loaded', handleModelLoaded)

            clone(event.detail.model)
        }

        if (object3DTargetOriginal) {
            clone(object3DTargetOriginal)
        } else {
            target.addEventListener('model-loaded', handleModelLoaded);
        }
    },
});