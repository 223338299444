import { useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Results.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import { clsx } from 'clsx'
import ErrorModal from './parts/ErrorModal'
import { useModalContext } from '../context/ModalContext'
import { LOCAL_STORAGE_REGION_KEY, LOCAL_STORAGE_RESULTS_KEY } from '../data/app'
import { useRegionContext } from '../context/RegionContext'

interface Props { }
interface Results {
    time: number
    scoops: number
    points: number
}

function fmtMSS(s: number) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

export const Results = ({ }: Props) => {
    const { showModal } = useModalContext();
    const storedValue: Results = JSON.parse(localStorage.getItem(LOCAL_STORAGE_RESULTS_KEY) || '{}');

    const points = storedValue?.points?.toString() || '0'
    const time = storedValue?.time && fmtMSS(storedValue?.time) || '0'
    const scoops = storedValue?.scoops?.toString() || '0'

    const url = new URL(process.env.REACT_APP_MICROSITE_URL || window.location.origin)

    url.pathname = '/congratulations'
    url.searchParams.append('s', points)

    if (process.env.REACT_APP_SITE_LOCATION === 'AE') {
        const { region } = useRegionContext()
        if (region) {
            url.searchParams.append('t', region)
        }
    }

    const handleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Enter the Flavour-Verse',
                    text: 'Play The Game To Save Our Flavours',
                    url: window.location.origin,
                })
                .then(() => {
                    // do something on success
                })
                .catch(() => {
                    // handle errors
                });
        } else {
            console.log('nop')
            showModal({
                content: <ErrorModal msg="Sharing is not enabled on your device." />,
            });
        }
    };

    return (
        <div className={styles.root}>
            <div className={clsx(styles.menu, 'menu pt-[100px]')}>
                <Wrapper className='text-center'>
                    <LayeredHeading
                        text='Game over'
                        variant='light'
                        isPlain={false}
                        className="mb-[40px] text-[64px]"
                    />
                    <Paragraph
                        size="2xl"
                        className="text-white font-bold"
                    >
                        Your game stats
                    </Paragraph>
                    <Paragraph
                        className="text-white"
                    >
                        Time: {time}
                    </Paragraph>
                    <Paragraph
                        className="text-white"
                    >
                        Scoops saved: {scoops}
                    </Paragraph>
                    <div className='my-[40px]'>
                        <LayeredHeading
                            text='Score'
                            variant='light'
                            isPlain={false}
                            className="text-[64px]"
                        />
                    </div>
                    <div>
                        <LayeredHeading
                            text={points}
                            variant='light'
                            isPlain={true}
                            className="text-[112px]"
                        />
                    </div>

                    <div className="w-full mt-[72px] mb-4">
                        <Button
                            as="a"
                            url={url.href}
                            variation="dark"
                            text="Claim my prize"
                            isWide
                            isTranslatable
                        />
                    </div>

                    <div className="flex space-x-3 w-full">
                        <div className="w-full">
                            <Button
                                onClick={handleShare}
                                as="button"
                                text="Share"
                                isWide
                                isTranslatable
                            />
                        </div>
                        <div className="w-full">
                            <Button
                                as="link"
                                url='/game'
                                text="Play again"
                                isWide
                                isTranslatable
                            />
                        </div>
                    </div>
                    <Paragraph size="xs" className="my-[20px] text-white">
                        © & ™ 2023 MARVEL. ©2023 SPAI. All Rights Reserved. Check the classification. T&Cs Apply.
                    </Paragraph>
                </Wrapper>
            </div>
        </div>
    )
}

function showModal(arg0: { content: JSX.Element }) {
    throw new Error('Function not implemented.')
}
