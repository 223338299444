import { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { IceCream } from "./IceCream"
import { useInterval } from 'usehooks-ts'
import usePrevious from "../hooks/usePrevious"
import { Entity } from "aframe"
import * as THREE from 'three'
import { sphericalCoordinateRandom } from "../utilities/sphericalCoordinateRandom"
import range from 'lodash/range'
import { sphericalCoordinate } from "../utilities/sphericalCoordinate"
import { randomInt } from "../utilities/randomInt"
import useSound from "../hooks/useSound"
import { useGameState } from "./GameState"

interface Props {
    isActive: boolean
    index: number
    handleRemove: (index: number) => void
    handleIndicatorShow: (index: number, direction: string) => void
    handleIndicatorHide: (index: number) => void
    addPosition: (position: THREE.Vector3) => void
    removePosition: (position: THREE.Vector3) => void
    positions: THREE.Vector3[]
}

const getPositionRandom = () => {
    // From left (270) to right (90)
    const fiRandom = randomInt(0, 360, range(90, 360 - 90))
    // On ground
    const thetaRandom = randomInt(90, 90, [])
    // 8th Wall scales everything relative to camera height, which is 1.6, so need to acount for this in the radius or things seems very far away
    const rRandom = randomInt(5, 10, [])

    const coordinate = sphericalCoordinate(fiRandom, thetaRandom, rRandom)

    const vector = new THREE.Vector3(coordinate.x, coordinate.y, coordinate.z);

    return vector
}

export const PortalCharacterSpot = ({
    isActive,
    index,
    handleRemove,
    positions,
    addPosition,
    removePosition,
    handleIndicatorShow,
    handleIndicatorHide,
}: Props) => {
    const [portalPosition, setPortalPosition] = useState<THREE.Vector3 | null>(null)
    const [isDead, setIsDead] = useState(true)

    useEffect(() => {
        if (isActive) {
            setIsDead(false)

            while (true) {
                const positionRandom = getPositionRandom()
                const positionOverlap = positions.some((position) => {
                    const distance = position.distanceTo(positionRandom)
                    if (distance < 5) {
                        return true
                    } else {
                        return false
                    }
                })

                // console.log('loop PortalCharacterSpot')

                if (!positionOverlap) {
                    setPortalPosition(positionRandom)
                    addPosition(positionRandom)
                    break;
                }
            }
        }

        return () => {
            if (portalPosition) {
                // console.log('removePosition PortalCharacterSpot')
                removePosition(portalPosition)
            }
        }
    }, [isActive])

    // Spawning

    const refRoot = useRef<Entity>(null)

    // Animation

    const refModel = useRef<Entity>(null)

    const { onSpotPortal, onSpotDie } = useSound()
    useLayoutEffect(() => {
        const model = refModel.current
        const video = document.querySelector('#spot') as unknown as HTMLVideoElement | null

        if (isActive) {
            model?.setAttribute('visible', 'true')

            if (video) {
                video.currentTime = 0
                video.play()
                onSpotPortal()

                const handleEnded = () => {
                    video.removeEventListener('ended', handleEnded)

                    video.pause()
                    video.currentTime = 0

                    handleRemovePortal()
                }

                video.addEventListener('ended', handleEnded)
            }
        } else {
            if (video) {
                video.pause()
                video.currentTime = 0
            }
        }
    }, [isActive])

    const handleTrackerShow = (event: any) => {
        handleIndicatorShow(index, event.detail.direction)
    }

    const handleTrackerHide = () => {
        handleIndicatorHide(index)
    }

    const handleRemovePortal = () => {
        const model = refModel.current

        model?.setAttribute('visible', 'false')
        handleRemove(index)

        if (portalPosition) {
            removePosition(portalPosition)
        }
    }

    // useEffect(() => {
    //     refRoot.current?.addEventListener('show', handleTrackerShow)
    //     refRoot.current?.addEventListener('hide', handleTrackerHide)

    //     return () => {
    //         refRoot.current?.removeEventListener('show', handleTrackerShow)
    //         refRoot.current?.removeEventListener('hide', handleTrackerHide)
    //     }
    // }, [])

    const { dispatch } = useGameState()
    useLayoutEffect(() => {
        // Get entity
        const model = refModel.current

        const handleDie = () => {
            setIsDead(true)

            if (!isDead) {
                onSpotDie()
                dispatch({ type: 'setPoints', payload: 6 })
            }
        }

        model?.addEventListener('die', handleDie);

        return () => {
            // context?.revert()
            model?.removeEventListener('die', handleDie);
        }
    }, [isDead])

    // Load upfront
    useEffect(() => {
        const model = refModel.current

        if (model?.hasLoaded) {
            model?.setAttribute('visible', 'false')
        } else {
            const handleModelLoad = () => {
                model?.removeEventListener('object3dset', handleModelLoad)

                setTimeout(() => {
                    model?.setAttribute('visible', 'false')
                }, 0)
            }

            model?.addEventListener('object3dset', handleModelLoad)
        }
    }, [])

    return (
        <a-entity
            position={`${portalPosition?.x} ${portalPosition?.y} ${portalPosition?.z}`}
        // position={`-0 0 -4`}
        >
            <a-entity
                ref={refModel}
                src="#spot"
                look-at="#camera"
                position="0 2 0"
                target="healthPoints: 1; static: false;"
                className="cantap"
                visible="true"
                geometry="primitive: plane; width: 3; height: 1.6899999999;"
                material="shader: chromakey; src: #spot; color: 0 1 0);"
            />
        </a-entity>
    )
}
