import { LOCAL_STORAGE_SOUND_KEY } from "../data/app";
import React, { useEffect, useRef, useState } from "react";
import { Howl } from "howler";
import { soundSource } from "../data/constants";

interface SoundContextProps {
  isSoundPermitted: boolean;
  setSoundPermission: (value: boolean) => void;
}

export const SoundContext = React.createContext<SoundContextProps>({
  isSoundPermitted: true,
  setSoundPermission: (value: boolean) => null,
});

export const SoundContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSoundPermitted, setPermission] = useState<boolean>(false);
  const soundRef = useRef<any>(null);

  useEffect(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_SOUND_KEY);
    if (storedValue) {
      setPermission(JSON.parse(storedValue));
    }
  }, []);

  useEffect(() => {
    soundRef.current = new Howl({
      src: [soundSource.pageBackground],
      volume: 0,
      loop: false,
      autoplay: true,
      onunlock: () => {
        setSoundPermission(true)
      }
    });

    if (isSoundPermitted) {
      soundRef.current.play();
    } else {
      soundRef.current.stop();
    }

    return () => {
      soundRef.current?.stop();
    };
  }, [isSoundPermitted]);

  const setSoundPermission = (value: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_SOUND_KEY, JSON.stringify(value));
    setPermission(value);
  };

  return (
    <SoundContext.Provider
      value={{
        isSoundPermitted,
        setSoundPermission,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};

export const useSoundContext = () => React.useContext(SoundContext);
