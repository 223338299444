import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import { Home } from './views/Home'
import { NotFound } from './views/NotFound'
import { Scene } from './views/Scene'
import { Results } from './views/Results'

import './assets/styles/globals.scss'
import Modal from './components/global/Modal'
import RegionModalSet from './components/global/RegionModalSet'
import { ModalContextProvider } from './context/ModalContext'
import { SoundContextProvider } from './context/SoundContext'
import { Instructions } from './components/Instructions'
import { RegionContextProvider } from './context/RegionContext'
import { useEffect } from 'react'
import { GoogleTagManagerProvider } from './context/GoogleTagManagerProvider'

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const App = () => {
  return (
    <SoundContextProvider>
      <RegionContextProvider>
        <ModalContextProvider>
          <BrowserRouter>
            <GoogleTagManagerProvider>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/instructions' element={<Instructions />} />
                <Route path='/game' element={<Scene />} />
                <Route path='/game/results' element={<Results />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            </GoogleTagManagerProvider>
          </BrowserRouter>
          <RegionModalSet />
          <Modal />
        </ModalContextProvider>
      </RegionContextProvider>
    </SoundContextProvider>
  )
}

export default App
