import { Entity, Scene } from "aframe"
import gsap from "gsap"
import { useContext, useEffect, useLayoutEffect, useRef } from "react"
import * as THREE from 'three'
import { gameContext } from "./GameState"
import { useNotifcations } from "./NotificationsProvider"

interface Props { }

export const Arm = ({ }: Props) => {
    const refRoot = useRef<Entity>(null)
    const gameState = useContext(gameContext)

    useEffect(() => {
        const elementGun = document.querySelector('#gun')

        const handleShoot = () => {
            const model = refRoot.current

            gameState?.dispatch({ type: 'setBullets' })

            model?.removeAttribute('animation-mixer')
            model?.setAttribute('animation-mixer', `clip: clip; loop: once;`)

            const handleAnimationFinished = () => {
                model?.removeEventListener('animation-finished', handleAnimationFinished)

                model?.removeAttribute('animation-mixer')
            }

            model?.addEventListener('animation-finished', handleAnimationFinished)
        }

        elementGun.addEventListener('shoot', handleShoot)

        return () => {
            elementGun.removeEventListener('shoot', handleShoot)
        }
    }, [])

    // const { dispatch } = useNotifcations()
    // useEffect(() => {
    //     const elementGun = document.querySelector('#gun')

    //     const handleShoot = () => {
    //         const statuses = ['critical', 'normal']
    //         const status = statuses[Math.floor(Math.random() * statuses.length)]

    //         dispatch({
    //             type: 'addNotification',
    //             payload: {
    //                 id: Date.now(),
    //                 text: String(Date.now()),
    //                 prefix: '+',
    //                 suffix: 'pts',
    //                 status: status as "critical" | "normal"
    //             }
    //         })
    //     }

    //     elementGun.addEventListener('shoot', handleShoot)

    //     return () => {
    //         elementGun.removeEventListener('shoot', handleShoot)
    //     }
    // }, [])

    return (
        <a-entity
            ref={refRoot}
            gltf-model="#arm"
            scale="0.05 0.05 0.05"
            position="0 -1 -0.5"
            rotation="180 0 180"
            shooter
            click-to-shoot
        />
    )
}
