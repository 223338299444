import { useEffect, useState } from 'react'
// @ts-expect-error
import styles from './Loading.module.scss'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'

interface Props {
    isLoaded: boolean
    handleLoaded: (isLoaded: boolean) => void
}

export const Loading = ({ isLoaded, handleLoaded }: Props) => {
    // it seems necessary to add some extra buffer to the loading time
    const [isLoadedDelay, setIsLoadedDelay] = useState(2000)
    const [isActive, setIsActive] = useState(true)

    const handleLoadedDelay = () => {
        setTimeout(() => {
            setIsActive(false)
            handleLoaded(true)
        }, isLoadedDelay)
    }

    useEffect(() => {
        if (isLoaded) {
            handleLoadedDelay()
        }
    }, [isLoaded])

    return (
        <>
            {isActive &&
                <div className={styles.root}>
                    <video
                        className={styles.background}
                        autoPlay
                        muted
                        playsInline
                        loop
                    >
                        <source src="https://d2kflxmfwybvzl.cloudfront.net/video/background.mp4" type="video/mp4" />
                    </video>
                    <div className='my-auto'>
                        <img
                            src="https://d2kflxmfwybvzl.cloudfront.net/images/loader.gif"
                            width="315"
                            height="122"
                            className='mx-auto'
                        />

                        <Wrapper>
                            <Paragraph size="xl" className="my-[20px] font-comic uppercase text-white">
                                Loading...
                            </Paragraph>
                        </Wrapper>
                    </div>

                    <Wrapper>
                        <Paragraph size="xs" className="my-[20px] text-white">
                            © & ™ 2023 MARVEL. ©2023 SPAI. All Rights Reserved.
                        </Paragraph>
                    </Wrapper>
                </div>
            }
        </>
    )
}