import React from "react";

function ReturnIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="69"
            fill="none"
            viewBox="0 0 68 69"
            {...props}
        >
            <g filter="url(#filter0_dd_670_18257)">
                <path
                    stroke="#FEFA00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M36.625 43.437v-5.25a.875.875 0 00-.875-.875h-3.5a.875.875 0 00-.875.875v5.25a.875.875 0 01-.875.875h-5.25a.875.875 0 01-.875-.875V33.319a.907.907 0 01.284-.645l8.75-7.951a.875.875 0 011.182 0l8.75 7.951a.906.906 0 01.284.645v10.118a.875.875 0 01-.875.875H37.5a.875.875 0 01-.875-.875z"
                ></path>
            </g>
            <circle
                cx="34"
                cy="34.687"
                r="28"
                fill="#282800"
                fillOpacity="0.4"
            ></circle>
            <g filter="url(#filter1_dd_670_18257)">
                <circle
                    cx="34"
                    cy="34.687"
                    r="27"
                    stroke="#FEFA00"
                    strokeWidth="2"
                ></circle>
            </g>
            <defs>
                <filter
                    id="filter0_dd_670_18257"
                    width="40"
                    height="40"
                    x="14"
                    y="14.687"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_670_18257"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_670_18257"
                        result="effect2_dropShadow_670_18257"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_670_18257"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_670_18257"
                    width="68"
                    height="68"
                    x="0"
                    y="0.687"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_670_18257"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_670_18257"
                        result="effect2_dropShadow_670_18257"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_670_18257"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default ReturnIcon;