import { useContext, useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Shoot.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import { gameContext } from './GameState'
import ShootIcon from '../assets/icons/ShootIcon'
import useSound from '../hooks/useSound'

interface Props { }

export const Shoot = ({ }: Props) => {
    const gameState = useContext(gameContext)
    const bullets = gameState?.state.bullets
    const isEmpty = bullets === 0
    const isBulletLevel1 = bullets! < 5
    const isBulletLevel2 = bullets! < 2

    const { onShoot, onShootLow, onShootEmpty, onImpact } = useSound()

    const elementGun = document.querySelector('#gun')
    const handleClick = () => {
        if (isEmpty) {
            onShootEmpty()
        } else if (isBulletLevel2) {
            onShootLow()
        } else if (isBulletLevel1) {
            onShootLow()
        } else {
            onShoot()
        }

        if (!isEmpty) {
            elementGun.emit('shoot')
        }
    }

    const scoops = gameState?.state.scoops

    useEffect(() => {
        if (scoops) {
            onImpact()
        }
    }, [scoops])

    return (
        <>
            <button className={styles.button} onClick={handleClick}>
                <ShootIcon className="opacity-[0.1]" />
            </button>
        </>
    )
}