import React from "react";

interface ModalContextProps {
  isShow: boolean,
  renderClose: boolean,
  content: React.ReactNode | null,
  showModal: ({ content, hasClose }: ShowModalProps) => void,
  onMondalClose: () => void;
}

interface ShowModalProps {
  content: React.ReactNode | null;
  hasClose?: boolean
}

export const ModalContext = React.createContext<ModalContextProps>({
  isShow: false,
  renderClose: false,
  content: null,
  showModal: () => null,
  onMondalClose: () => null,
});

export const ModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isShow, setModalState] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode | null>(null);
  const [renderClose, setRenderClose] = React.useState(false)

  const showModal = ({ content, hasClose }: ShowModalProps) => {
    setContent(content);
    setModalState(true);

    if(hasClose) setRenderClose(true)
  };

  const onMondalClose = () => {
    setModalState(false);
  }


  return (
    <ModalContext.Provider
      value={{
        isShow,
        renderClose,
        content,
        showModal,
        onMondalClose
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalContext);
