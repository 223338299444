import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import { LOCAL_STORAGE_REGION_KEY } from "../data/app";

interface RegionContextProps {
    region: string | null,
    handleSetRegion: (region: string) => void;
}

export const RegionContext = createContext<RegionContextProps>({
    region: null,
    handleSetRegion: () => null
});

export const RegionContextProvider = ({ children }: { children: React.ReactNode }) => {
    const storedValueRegion = localStorage.getItem(LOCAL_STORAGE_REGION_KEY);
    const storedValueRegionParsed = storedValueRegion && JSON.parse(storedValueRegion)

    const [region, setRegion] = useState(storedValueRegionParsed || null)

    const handleSetRegion = (region: string) => {
        localStorage.setItem(LOCAL_STORAGE_REGION_KEY, JSON.stringify(region));
        setRegion(region)
    }

    return (
        <RegionContext.Provider
            value={{
                region,
                handleSetRegion
            }}
        >
            {children}
        </RegionContext.Provider>
    );
};

export const useRegionContext = () => useContext(RegionContext);
