import { Link } from 'react-router-dom'
import { Landing } from '../components/Landing'

const Home = () => (
    <>
        <Landing />
    </>
)

export { Home }
