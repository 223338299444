import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { gameContext } from './GameState'
import { useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_RESULTS_KEY } from '../data/app'
import { useNotifcations } from './NotificationsProvider'
import useSound from '../hooks/useSound'

interface Results {
    time: number
    scoops: number
    points: number
}

interface Props {
    children?: ReactNode
    expiryTimestamp: Date
    secondsTotal: number
}

type State = {
    seconds: number,
    minutes: number,
    progress: number
}

type Action = {
    type: 'setTime',
    payload?: State
}

export const timerContext = createContext<{ state: State, dispatch: Dispatch<Action> } | null>(null);

const reducer = (state: State, action: Action) => {
    if (action.type === 'setTime') {
        return action.payload!
    }

    return state
}

export const GameTimer = ({ children, expiryTimestamp, secondsTotal }: Props) => {
    const {
        seconds,
        minutes,
        start
    } = useTimer({
        expiryTimestamp,
        autoStart: false,
        onExpire: () => {
            handleExpiry()
        }
    });

    const initialState: State = {
        seconds,
        minutes,
        progress: secondsTotal / secondsTotal
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const { dispatch: dispatchNotifications } = useNotifcations()
    const { onTimer0, onTimer10 } = useSound()

    useEffect(() => {
        const secondsTotalCurrent = (minutes * 60) + seconds

        dispatch({
            type: 'setTime', payload: {
                seconds,
                minutes,
                progress: secondsTotalCurrent / secondsTotal
            }
        })

        if (secondsTotalCurrent === 10) {
            onTimer10()
            dispatchNotifications({
                type: 'addNotification',
                payload: {
                    id: Date.now(),
                    text: '10s remaining',
                    status: 'critical'
                }
            })
        }
    }, [seconds, minutes])

    const gameState = useContext(gameContext)

    // const points = gameState?.state?.points

    // useEffect(() => {
    //     const newExpiryTimestamp = expiryTimestamp

    //     newExpiryTimestamp.setSeconds(expiryTimestamp.getSeconds() - 10)

    //     restart(newExpiryTimestamp)
    // }, [points])

    useEffect(() => {
        if (gameState.state.started) {
            start()
        }
    }, [gameState.state.started])

    const navigate = useNavigate();
    const handleExpiry = () => {
        onTimer0()

        const storedValue: Results = {
            time: secondsTotal,
            scoops: gameState?.state?.scoops!,
            points: gameState?.state?.points!,
        };

        localStorage.setItem(LOCAL_STORAGE_RESULTS_KEY, JSON.stringify(storedValue));

        navigate('/game/results')
    }

    return (
        <timerContext.Provider value={{ state, dispatch }}>
            {children}
        </timerContext.Provider>
    )
}

export const useGameTimer = () => useContext(timerContext);