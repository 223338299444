export const sphericalCoordinate = (fiRandom: number, thetaRandom: number, rRandom: number) => {
    // lets change it to radians
    // fi = horizontal axis (0 - 360) (e.g. 0 = front, 90 = right, 180 = behind)
    // theta = vertical axis (0 - 360) (e.g. 90 = front, 45 = up in front, 0 = directly up above)
    // r = radius (ie. size of sphere)
    const fi = (fiRandom * Math.PI) / 180
    const theta = (thetaRandom * Math.PI) / 180
    const r = rRandom

    // The 'horizontal axis is x. The 'vertical' is y.
    // The calculations below are straight from the wiki site.
    const z = -1 * Math.sin(theta) * Math.cos(fi) * r
    const x = Math.sin(theta) * Math.sin(fi) * r
    const y = Math.cos(theta) * r
    // position the element using the provided data
    return {
        x,
        y,
        z,
    }
}
