import React from "react";

function ReloadIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="69"
            fill="none"
            viewBox="0 0 68 69"
            {...props}
        >
            <g
                stroke="#FEFA00"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                filter="url(#filter0_dd_670_18255)"
            >
                <path d="M39.272 31.591h5.25v-5.25"></path>
                <path d="M40.803 41.49a9.625 9.625 0 110-13.607l3.719 3.708"></path>
            </g>
            <circle
                cx="34"
                cy="34.687"
                r="28"
                fill="#282800"
                fillOpacity="0.4"
            ></circle>
            <g filter="url(#filter1_dd_670_18255)">
                <circle
                    cx="34"
                    cy="34.687"
                    r="27"
                    stroke="#FEFA00"
                    strokeWidth="2"
                ></circle>
            </g>
            <defs>
                <filter
                    id="filter0_dd_670_18255"
                    width="40"
                    height="40"
                    x="14"
                    y="14.687"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_670_18255"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_670_18255"
                        result="effect2_dropShadow_670_18255"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_670_18255"
                        result="shape"
                    ></feBlend>
                </filter>
                <filter
                    id="filter1_dd_670_18255"
                    width="68"
                    height="68"
                    x="0"
                    y="0.687"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.556863 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_670_18255"
                    ></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.980392 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        in2="effect1_dropShadow_670_18255"
                        result="effect2_dropShadow_670_18255"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_670_18255"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export default ReloadIcon;