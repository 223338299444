import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalCloseIcon from "../../assets/icons/ModalCloseIcon";
import PreviousArrow from "../../assets/icons/PreviousArrow";
import { useModalContext } from "../../context/ModalContext";
import Button from "./Button";
import Paragraph from "./Paragraph";
import GaugeScore from "../../assets/svg/GaugeScore";

interface IntroModalProps {
    onClose: () => void
    currentIndex: number
    setCurrentIndex: Dispatch<SetStateAction<number>>
}

const items = [
    {
        title: 'GetTING STARTED',
        text: 'If this is your first time playing, here are 3 quick tips to get you ready. Dismiss this if you just want to get started?',
    },
    {
        title: 'Reload',
        text: 'Fire 10 web shots before you need to reload. Press the reload button when you’re running low.',
    },
    {
        title: 'Timer',
        text: 'Collect as many ice creams as you can in 90s. Keep an eye on the clock so you’ll know how much time you have left.',
    },
    {
        title: 'Score',
        text: 'Press the button below to fire webs. Hit 5 ice creams in a row to double your points and 5 more to triple it.',
    },
]

const IntroModal = ({ currentIndex, setCurrentIndex, onClose }: IntroModalProps) => {
    // Close
    const { onMondalClose } = useModalContext()

    const handleClose = () => {
        onClose()
        onMondalClose()
    }

    // Steps
    const [isFirst, setIsFirst] = useState(true)
    const [isLast, setIsLast] = useState(false)

    useEffect(() => {
        if (currentIndex === 0) {
            setIsFirst(true)
        } else {
            setIsFirst(false)
        }

        if (currentIndex === items.length - 1) {
            setIsLast(true)
        } else {
            setIsLast(false)
        }
    }, [currentIndex])

    const handleClickPrevious = (isFirst: boolean) => {
        if (!isFirst) {
            setCurrentIndex((currentIndex) => {
                return currentIndex - 1
            })
        } else {
            handleClose()
        }
    }

    const handleClickNext = (isLast: boolean) => {
        if (!isLast) {
            setCurrentIndex((currentIndex) => {
                return currentIndex + 1
            })
        } else {
            handleClose()
        }
    }

    return (
        <>
            <div className="max-w-[320px] -translate-y-[30px]">
                <div className="flex justify-end mb-4">
                    <button onClick={handleClose}><ModalCloseIcon /></button>
                </div>
                <div className=" border-4 border-black rounded bg-white relative">
                    <div className="dots-bg dots-bg--orange bg-brand-yellow px-6 py-3 border-b-4 border-black flex items-center justify-center">
                        <Paragraph as="span" className="uppercase font-semibold text-xl text-black-shadow font-comic inline-block leading-none">
                            {items[currentIndex].title}
                        </Paragraph>
                    </div>
                    <div className="p-6 pb-10 text-center space-y-4">

                        <Paragraph as="p" size="sm">
                            {items[currentIndex].text}
                        </Paragraph>

                        <div className="absolute left-[50%] translate-x-[-50%] w-max">
                            <div className="flex justify-between w-full space-x-3">
                                <Button
                                    variation="light"
                                    as="button"
                                    icon={
                                        !isFirst && <PreviousArrow className="my-[5px] w-[35px] scale-90" />
                                    }
                                    iconText={!isFirst}
                                    text={isFirst ? 'Dismiss' : undefined}
                                    onClick={() => {
                                        handleClickPrevious(isFirst)
                                    }}
                                />
                                <Button
                                    variation="dark"
                                    as="button"
                                    text={!isLast ? "Next" : "Play the game"}
                                    isWide
                                    onClick={() => {
                                        handleClickNext(isLast)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntroModal;
