import { createPortal } from "react-dom"
import { Arm } from "./Arm";
import { GameState } from "./GameState";
import { Gauges } from "./Gauges";
import { GameTimer } from "./GameTimer";
import { Portals } from "./Portals";
import { Reload } from "./Reload";
import { Shoot } from "./Shoot";
import { Return } from "./Return";
import { Test } from "./Test";
import THREE, { Camera } from "three";
import { Entity } from "aframe";
import { Notifications } from "./Notifications";
import { NotificationsProvider } from "./NotificationsProvider";
import { Intro } from "./Intro";
import { CountDown } from "./CountDown";
import { useEffect, useState } from "react";
import { PortalsCharacterSpot } from "./PortalsCharacterSpot";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'a-entity': any;
            'a-plane': any;
            'a-image': any;
            'a-video': any;
            'a-box': any;
        }
    }
}

interface Props {
    isLoaded: boolean
}

export const Game = ({ isLoaded }: Props) => {
    const expiryTimestamp = new Date();
    const secondsTotal = 1.5 * 60
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + secondsTotal)

    const [isIntroDone, setIsIntroDone] = useState(false)

    // Positions
    const [positions, setPositions] = useState<THREE.Vector3[]>([])

    return (
        <>
            <NotificationsProvider>
                <GameState>
                    <GameTimer expiryTimestamp={expiryTimestamp} secondsTotal={secondsTotal}>
                        {
                            createPortal(
                                <>
                                    <Portals isLoaded={isLoaded} positions={positions} setPositions={setPositions} />
                                    <PortalsCharacterSpot isLoaded={isLoaded} positions={positions} setPositions={setPositions} />
                                    {/* <Test /> */}
                                    {
                                        createPortal(
                                            <>
                                                <Arm />
                                            </>,
                                            document.querySelector('a-camera')
                                        )
                                    }
                                </>,
                                document.querySelector('a-scene')
                            )
                        }
                        <Gauges />
                        <Notifications />
                        <Reload />
                        <Shoot />
                        <Return />
                        {isLoaded && <Intro setIsIntroDone={setIsIntroDone} />}
                        {isLoaded && isIntroDone && <CountDown />}
                    </GameTimer>
                </GameState>
            </NotificationsProvider>
        </>
    )
}
