import { useEffect, useReducer, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { IceCream } from "./IceCream"
import { useInterval } from 'usehooks-ts'
import { Portal } from "./Portal";
import { useGameState } from "./GameState";
// @ts-expect-error
import styles from './Portals.module.scss'
import clsx from "clsx";
import { Indicators } from "./Indicators";
import { PortalCharacterSpot } from "./PortalCharacterSpot";
import { randomInt } from "../utilities/randomInt";


interface Props {
    isLoaded: boolean
    positions: THREE.Vector3[]
    setPositions: React.Dispatch<React.SetStateAction<THREE.Vector3[]>>
}

interface Item {
    id: number
    enabled: boolean
    indicator: string | null
}

type Action = {
    type: 'setItems' | 'itemEnable' | 'itemDisable' | 'itemIndicatorEnable' | 'itemIndicatorDisable',
    payload?: any
}

const reducer = (state: Item[], action: Action) => {
    if (action.type === 'setItems') {
        return action.payload
    } else if (action.type === 'itemEnable') {
        const stateNew: Item[] = JSON.parse(JSON.stringify(state))
        const itemDisabled = stateNew.find((item) => {
            return item.enabled === false
        })

        const itemsNew = stateNew.map((item) => {
            if (item.id === itemDisabled?.id) {
                item.enabled = true
            }

            return item
        })

        return itemsNew
    } else if (action.type === 'itemDisable') {
        const stateNew: Item[] = JSON.parse(JSON.stringify(state))
        const itemsNew = stateNew.map((item) => {
            if (item.id === action.payload) {
                item.enabled = false
            }

            return item
        })

        return itemsNew
    } else if (action.type === 'itemIndicatorEnable') {
        const stateNew: Item[] = JSON.parse(JSON.stringify(state))
        const itemsNew = stateNew.map((item) => {
            if (item.id === action.payload.id) {
                item.indicator = action.payload.direction
            }

            return item
        })

        return itemsNew
    } else if (action.type === 'itemIndicatorDisable') {
        const stateNew: Item[] = JSON.parse(JSON.stringify(state))
        const itemsNew = stateNew.map((item) => {
            if (item.id === action.payload.id) {
                item.indicator = null
            }

            return item
        })

        return itemsNew
    } else {
        return state
    }
}

const initialState: Item[] = []

export const PortalsCharacterSpot = ({ isLoaded, positions, setPositions }: Props) => {
    // Counter
    const [count, setCount] = useState<number>(0)
    // Counter Max
    const [countMax, setCountMax] = useState<number>(3 - 1)
    // Items
    const [items, setItems] = useReducer(reducer, initialState)
    // Dynamic delay
    const [delay, setDelay] = useState<number>(1000 * 20)
    // ON/OFF
    const [isPlaying, setPlaying] = useState<boolean>(false)

    useInterval(
        () => {
            // Increase count
            setCount(count + 1)
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? delay : null,
    )

    useEffect(() => {
        setItems({
            type: 'setItems', payload: [...Array(countMax).keys()].map((item) => {
                return {
                    id: item,
                    enabled: false
                }
            })
        })
    }, [])

    useEffect(() => {
        if (isPlaying) {
            if (count === countMax) {
                setPlaying(false)
            }

            setItems({
                type: 'itemEnable'
            })
        }
    }, [count, isPlaying])

    const { state: gameState } = useGameState()
    useEffect(() => {
        if (isLoaded && gameState.started) {
            const randomDelay = randomInt(1000 * (0 + 15), 1000 * (0 + 25), [])
            setTimeout(() => {
                setPlaying(true)
            }, randomDelay)
        }
    }, [isLoaded, gameState.started])

    const handleRemovePortal = (index: number) => {
        // Decrease count
        // setCount((count) => {
        //     return count - 1
        // })

        // Disable item
        setItems({
            type: 'itemDisable',
            payload: index
        })

        // Remove item
        // setItems((items) => {
        //     const itemsFiltered = items.filter((item) => {
        //         return item !== index
        //     })

        //     return itemsFiltered
        // })
    }

    const handleIndicatorShow = (index: number, direction: string) => {
        setItems({
            type: 'itemIndicatorEnable',
            payload: {
                id: index,
                direction
            }
        })
    }

    const handleIndicatorHide = (index: number) => {
        setItems({
            type: 'itemIndicatorDisable',
            payload: {
                id: index
            }
        })
    }

    const addPosition = (position: THREE.Vector3) => {
        setPositions((positionsCurrent) => {
            positionsCurrent.push(position)
            const positionsNew = [...positionsCurrent]
            return positionsNew
        })
    }

    const removePosition = (position: THREE.Vector3) => {
        setPositions((positionsCurrent) => {
            const positionsNew = positionsCurrent.filter((positionCurrent, index) => {
                return !positionCurrent.equals(position)
            })
            return positionsNew
        })
    }

    return (
        <>
            {items.map((item: Item) => {
                return (
                    <PortalCharacterSpot
                        key={item.id}
                        index={item.id}
                        handleRemove={handleRemovePortal}
                        isActive={item.enabled}
                        addPosition={addPosition}
                        removePosition={removePosition}
                        positions={positions}
                        handleIndicatorShow={handleIndicatorShow}
                        handleIndicatorHide={handleIndicatorHide}
                    />
                )
            })}
        </>
    )
}
