import classNames from "classnames";
import React from "react";

interface WrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  [propName: string]: any;
}

const Wrapper = ({ children, className, ...otherProps }: WrapperProps) => {
  return (
    <div
      className={classNames(
        "container",
        "relative mx-auto px-[42px] md:px-8",
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default Wrapper;
