import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import gsap from "gsap";
import { useModalContext } from "../../context/ModalContext";

const Modal = () => {
  const [isBrowser, setIsBrowser] = useState(false);
  const { isShow, content } = useModalContext();
  const modalRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (modalContentRef.current === null) return;

    const tl = gsap.timeline({ defaults: { duration: 0.6 } });

    if (isShow) {
      tl.to(
        modalRef.current,
        {
          backgroundColor: "rgba(0,0,0, 0.8)",
          onStart: () => {
            gsap.set(modalRef.current, { pointerEvents: "auto" });
          },
        },
        "sameTime"
      ).to(
        modalContentRef.current,
        {
          yPercent: 0,
          opacity: 1,
        },
        "sameTime"
      );
    } else {
      tl.to(
        modalRef.current,
        {
          backgroundColor: "rgba(0,0,0, 0)",
          onStart: () => {
            gsap.set(modalRef.current, { pointerEvents: "none" });
          },
        },
        "sameTime"
      ).to(
        modalContentRef.current,
        {
          yPercent: -100,
          opacity: 0,
        },
        "sameTime"
      );
    }
  }, [isShow]);

  // Height

  const resizeHandler = () => {
    let vvh = window.visualViewport?.height;
    document.documentElement.style.setProperty('--vvh', `${vvh}px`);
  }

  useLayoutEffect(() => {
    resizeHandler()

    window.visualViewport?.addEventListener('resize', resizeHandler)

    return () => {
      window.visualViewport?.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const modalContent = (
    <div
      ref={modalRef}
      className="fixed pointer-events-none top-0 left-0 w-full h-viewport flex items-center justify-center z-50"
    >
      <div ref={modalContentRef} className="relative">
        {content}
      </div>
    </div>
  );

  if (isBrowser) {
    const modalRoot = document.getElementById("modal-root");
    return modalRoot ? ReactDOM.createPortal(modalContent, modalRoot) : null;
  } else {
    return null;
  }
};

export default Modal;
