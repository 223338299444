import { useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Landing.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import useSound from '../hooks/useSound'
import { LOCAL_STORAGE_REGION_KEY } from '../data/app'
import { useRegionContext } from '../context/RegionContext'

interface Props { }

export const Landing = ({ }: Props) => {
    const { region } = useRegionContext()
    const isRegionAE = region === 'AE'

    const panelVideoRef = useRef<HTMLVideoElement>(null);
    const hexBackRef = useRef<HTMLImageElement>(null);
    const hexFrontRef = useRef<HTMLImageElement>(null);
    const herosRef = useRef<HTMLImageElement>(null);

    return (
        <div className={styles.root}>
            <video
                className={styles.background}
                autoPlay
                muted
                playsInline
                loop
            >
                <source src="https://d2kflxmfwybvzl.cloudfront.net/video/background.mp4" type="video/mp4" />
            </video>
            <div className={styles.portal}>
                <video
                    ref={panelVideoRef}
                    className={styles.portalVideo}
                    autoPlay
                    muted
                    playsInline
                >
                    <source src="https://d2kflxmfwybvzl.cloudfront.net/video/hero.mp4" type="video/mp4" />
                </video>

                <img
                    ref={hexFrontRef}
                    className={styles.portalBackground}
                    src="https://d2kflxmfwybvzl.cloudfront.net/images/portal/portal-background.png"
                    alt="orange hex single"
                />

                <img
                    ref={hexBackRef}
                    className={styles.portalForeground}
                    src="https://d2kflxmfwybvzl.cloudfront.net/images/portal/portal-foreground.png"
                    alt="orange hex multiple"
                />

                <img
                    ref={herosRef}
                    className={styles.portalCharacters}
                    src="https://d2kflxmfwybvzl.cloudfront.net/images/portal/portal-characters.png"
                    alt="heros"
                />
            </div>
            {isRegionAE || process.env.REACT_APP_SITE_LOCATION !== 'AE' ?
                <img
                    className='mx-auto'
                    src="https://d2kflxmfwybvzl.cloudfront.net/images/lockup-prize.png"
                    width="414"
                    height="226"
                /> :
                <img
                    className='mx-auto'
                    src="https://d2kflxmfwybvzl.cloudfront.net/images/lockup-flavourverse.png"
                    width="400"
                    height="220"
                />
            }
            <Wrapper className='text-center'>
                {!isRegionAE &&
                    <Paragraph
                        size="lg"
                        className="text-white"
                    >
                        Your mission is simple: Locate the portals and recapture the flavours with your virtual web-shooter.
                    </Paragraph>
                }
                <div className="relative z-10 flex flex-col justify-center items-center space-y-4 mt-[53px] mb-[53px]">
                    <div>
                        <Button
                            as="link"
                            text="Play the game"
                            url="/game"
                            variation="dark"
                        />
                    </div>
                    <div>

                        <Button
                            as="link"
                            text="How to play"
                            url='/instructions'
                            variation="light"
                        />
                    </div>
                </div>
                <div>
                    <img
                        src="https://d2kflxmfwybvzl.cloudfront.net/images/lockup-spiderman.gif"
                        width="315"
                        height="122"
                        className='mx-auto'
                    />

                    {/* <div>Only In Cinemas</div> */}
                </div>
                <Paragraph size="xs" className="my-[20px] text-white">
                    © & ™ 2023 MARVEL. ©2023 SPAI. All Rights Reserved. Check the classification. T&Cs Apply.
                </Paragraph>
            </Wrapper>
        </div>
    )
}