import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react'

interface Props {
    children?: ReactNode
}

type Notification = {
    id: number
    text: string
    prefix?: string
    suffix?: string
    status: "normal" | "critical"
}

type State = Notification[]

type Action = {
    type: 'addNotification',
    payload: Notification
}

const reducer = (state: State, action: Action) => {
    if (action.type === 'addNotification') {
        const stateNew: State = JSON.parse(JSON.stringify(state))

        stateNew.push(action.payload)

        return stateNew
    }
    return state
}

const initialState: State = []

export const NotificationsContext = createContext<{ state: State, dispatch: Dispatch<Action> }>({ state: initialState, dispatch: () => { } });

export const NotificationsProvider = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <NotificationsContext.Provider value={{ state, dispatch }}>
            {children}
        </NotificationsContext.Provider>
    )
}

export const useNotifcations = () => useContext(NotificationsContext);