import classNames from "classnames";
import React from "react";

type HeadingVariant = "light" | "dark";
interface LayeredHeadingProps {
  text: string;
  isPlain: boolean;
  variant: HeadingVariant;
  canBeHovered?: boolean;
  className?: string;
}

const LayeredHeading = ({
  text,
  isPlain,
  variant,
  canBeHovered,
  className,
}: LayeredHeadingProps) => {
  const textArr = text.split(" ");

  if (!isPlain && variant === "dark" && !canBeHovered) {
    return (
      <div className="relative inline-block">
        <div
          className={classNames(
            "layeredHeading layeredHeading--dark text-4xl font-pawlskinny relative inline-block",
            className
          )}
        >
          <span
            className="leading-[0.875em] uppercase block z-30 tracking-wider layerBackground"
            data-content={text}
          >
            <h2 className="layerForeground" data-content={text}>
              {text}
            </h2>
          </span>
        </div>
      </div>
    );
  }

  if (!isPlain && variant === "light" && !canBeHovered) {
    return (
      <div className="relative inline-block">
        <div
          className={classNames(
            "layeredHeading text-4xl font-pawlskinny relative inline-block",
            className
          )}
        >
          <span
            className="leading-[0.875em] uppercase block z-30 tracking-wider layerBackground"
            data-content={text}
          >
            <h2 className="layerForeground" data-content={text}>
              {text}
            </h2>
          </span>
        </div>
      </div>
    );
  }

  if (isPlain && canBeHovered && variant === "light") {
    return (
      <div className="relative group inline-block">
        <div
          className={classNames(
            "layeredHeading layeredHeading--hoverable layeredHeading--invert text-4xl font-pawlskinny relative inline-block",
            className
          )}
        >
          <span
            className="leading-[0.875em] uppercase block z-30 tracking-wider layerBackground"
            data-content={text}
          >
            <h2 className="layerForeground" data-content={text}>
              {text}
            </h2>
          </span>
        </div>
      </div>
    );
  }

  if (isPlain && canBeHovered && variant === "dark") {
    return (
      <div className="relative group inline-block">
        <div
          className={classNames(
            "layeredHeading layeredHeading--dark layeredHeading--hoverable text-4xl font-pawlskinny relative inline-block",
            className
          )}
        >
          <span
            className="leading-[0.875em] uppercase block z-30 tracking-wider layerBackground"
            data-content={text}
          >
            <h2 className="layerForeground" data-content={text}>
              {text}
            </h2>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "leading-[0.875em] text-4xl font-pawlskinny relative inline-block group",
        className
      )}
    >
      <span
        className={classNames(
          "relative block z-20 tracking-wider uppercase",
          variant === "dark" ? "text-black" : "text-white"
        )}
      >
        <h2>{text}</h2>
      </span>
    </div>
  );
};

export default LayeredHeading;
