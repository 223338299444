import { Dispatch, Ref, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Return.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import { gameContext, useGameState } from './GameState'
import ReturnIcon from '../assets/icons/ReturnIcon'
import { useModalContext } from '../context/ModalContext'
import ReturnModal from './parts/ReturnModal'
import IntroModal from './parts/IntroModal'
import GaugeScore from '../assets/svg/GaugeScore'
import { Transition } from 'react-transition-group'
import GaugeTimer from '../assets/svg/GaugeTimer'
import GaugeBullets from '../assets/svg/GaugeBullets'
import ReloadIcon from '../assets/icons/ReloadIcon'
import ShootIcon from '../assets/icons/ShootIcon'
import gsap from 'gsap'

interface Props {
    setIsIntroDone: Dispatch<SetStateAction<boolean>>
}

export const Intro = ({ setIsIntroDone }: Props) => {
    // Animation
    const [isOpen, setIsOpen] = useState(true)
    const refRoot = useRef<HTMLDivElement>(null)

    const handleEnter = (nodeRef: RefObject<HTMLDivElement>) => {
        gsap.fromTo(
            nodeRef.current,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
        )
    }

    const handleExit = (nodeRef: RefObject<HTMLDivElement>) => {
        gsap.fromTo(
            nodeRef.current,
            {
                autoAlpha: 1,
            },
            {
                autoAlpha: 0,
            },
        )
    }

    // Steps
    const [currentIndex, setCurrentIndex] = useState(0)
    const refStep2 = useRef(null)
    const refStep3 = useRef(null)
    const refStep4 = useRef(null)

    // Modal
    const { showModal } = useModalContext();

    const onClose = () => {
        setIsIntroDone(true)
        setIsOpen(false)
    }

    useEffect(() => {
        showModal({
            content: <IntroModal currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} onClose={onClose} />,
        });
    }, [currentIndex, setCurrentIndex])

    return (
        <Transition
            in={isOpen}
            timeout={1000}
            mountOnEnter
            unmountOnExit
            nodeRef={refRoot}
            onEnter={() => handleEnter(refRoot)}
            onExit={() => handleExit(refRoot)}
        >
            <div ref={refRoot}>
                <Transition
                    in={currentIndex === 1}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                    nodeRef={refStep2}
                    onEnter={() => handleEnter(refStep2)}
                    onExit={() => handleExit(refStep2)}
                >
                    <div ref={refStep2} className="relative z-[60]">
                        <div className="fixed z-[60] top-[16px] left-1/2 translate-x-[32px] w-[160px] grid gap-[20px] text-center">
                            <GaugeBullets className="w-full h-auto" />
                            <div className='uppercase text-white font-comic text-sm'>
                                <div>Web shots x10</div>
                            </div>
                        </div>
                        <div className="fixed z-[60] bottom-0 left-0 grid gap-[20px] my-[20px] mx-[47px] items-center text-center">
                            <div className='uppercase text-white font-comic text-sm'>
                                <div>REload</div>
                                <div>Button</div>
                            </div>
                            <ReloadIcon className="mx-auto" />
                        </div>
                    </div>
                </Transition>
                <Transition
                    in={currentIndex === 2}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                    nodeRef={refStep3}
                    onEnter={() => handleEnter(refStep3)}
                    onExit={() => handleExit(refStep3)}
                >
                    <div ref={refStep3} className="relative z-[60]">
                        <div className="fixed z-[60] top-[16px] left-1/2 -translate-x-[192px] w-[160px] grid gap-[20px] text-center">
                            <GaugeTimer className="w-full h-auto" />
                            <div className='uppercase text-white font-comic text-sm'>
                                <div>Timer</div>
                            </div>
                        </div>
                        <div className="fixed z-[60] bottom-0 right-0 grid gap-[20px] my-[20px] mx-[47px] items-center text-center">
                            <div className='uppercase text-white font-comic text-sm'>
                                <div>Return</div>
                                <div>Home</div>
                            </div>
                            <ReturnIcon className="mx-auto" />
                        </div>
                    </div>
                </Transition>
                <Transition
                    in={currentIndex === 3}
                    timeout={1000}
                    mountOnEnter
                    unmountOnExit
                    nodeRef={refStep4}
                    onEnter={() => handleEnter(refStep4)}
                    onExit={() => handleExit(refStep4)}
                >
                    <div ref={refStep4} className="relative z-[60]">
                        <div className="fixed z-[60] top-[-7px] left-1/2 -translate-x-1/2 w-[285px] grid gap-[20px] text-center">
                            <GaugeScore className="w-full h-auto" />
                            <div className='uppercase text-white font-comic text-sm'>
                                <div>Score</div>
                                <div>and multiplier</div>
                            </div>
                        </div>
                        <div className="fixed z-[60] bottom-0 left-1/2 -translate-x-1/2 grid gap-[20px] my-[20px] items-center text-center">
                            <div className='uppercase text-white font-comic text-sm'>
                                <div>Fire web</div>
                            </div>
                            <ShootIcon className="mx-auto" />
                        </div>
                    </div>
                </Transition>
            </div>
        </Transition>
    )
}