import classNames from "classnames";
import React from "react";
import Paragraph from "./Paragraph";
import { Link } from "react-router-dom";

interface ButtonPropTypes {
  as?: "a" | "button" | "link";
  newTab?: boolean;
  onClick?: () => void;
  url?: string;
  className?: string;
  fontClassName?: string;
  text?: string;
  iconText?: boolean;
  icon?: any;
  isTranslatable?: boolean;
  variation?: "light" | "dark";
  isWide?: boolean;
  disabled?: boolean;
}

const Button = (props: ButtonPropTypes) => {
  const {
    as = "link",
    onClick,
    disabled = false,
    url = "",
    className,
    fontClassName,
    newTab,
    text,
    variation = "light",
    iconText,
    icon,
    isTranslatable = false,
    isWide = false,
  } = props;

  const buttonChildren = () => {
    return (
      <div
        className={classNames(
          "relative group",
          disabled ? "opacity-30 pointer-events-none" : ""
        )}
      >
        <div className="absolute -left-[6px] top-[6px] w-full h-full bg-black rounded" />
        <div
          className={classNames(
            "border-[3px] border-black rounded uppercase transition-all relative top-0 left-0 group-active:-left-[6px] group-active:top-[6px]",
            "dots-bg",
            className,
            variation === "dark"
              ? "dots-bg--orange bg-brand-yellow"
              : " dots-bg--yellow bg-white"
          )}
        >
          <div
            className={classNames(
              "relative overflow-hidden flex items-center justify-center",
              icon ? "px-2 py-0" : "px-4 py-3"
            )}
          >
            {icon && <div className="scale-110">{icon}</div>}
            {!iconText && (
              <Paragraph
                as="span"
                size="sm"
                className={classNames(
                  "font-semibold text-black-shadow font-comic leading-none relative",
                  //(isTranslatable && lang === "AR") ? "" : "top-1",
                  fontClassName
                )}
                isTranslatable={isTranslatable}
              >
                {text}
              </Paragraph>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (as === "link") {
    return (
      <Link
        to={url ? url : "/"}
        className={classNames(className, isWide ? "w-full" : "")}
      >
        {buttonChildren()}
      </Link>
    );
  } else if (as === "button") {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={classNames(className, isWide ? "w-full" : "")}
      >
        {buttonChildren()}
      </button>
    );
  } else {
    // default
    return (
      <a
        href={url ? url : "#"}
        className={classNames(className, isWide ? "w-full" : "")}
        target={newTab ? "_blank" : undefined}
      >
        {buttonChildren()}
      </a>
    );
  }
};

export default Button;
