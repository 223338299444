import { useContext, useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Return.module.scss'
import { Link } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import { gameContext } from './GameState'
import ReturnIcon from '../assets/icons/ReturnIcon'
import { useModalContext } from '../context/ModalContext'
import ReturnModal from './parts/ReturnModal'

interface Props { }

export const Return = ({ }: Props) => {
    const { showModal } = useModalContext();
    const handleClick = () => {
        showModal({
            content: <ReturnModal />,
        });
    }

    return (
        <>
            <button className={styles.button} onClick={handleClick}>
                <ReturnIcon />
            </button>
        </>
    )
}