import { createRef, useEffect, useRef, useState } from 'react'
// @ts-expect-error
import styles from './Instructions.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import LayeredHeading from './parts/LayeredHeading'
import Button from './parts/Button'
import Paragraph from './parts/Paragraph'
import Wrapper from './parts/Wrapper'
import PreviousArrow from '../assets/icons/PreviousArrow'
import clsx from 'clsx'
import { LOCAL_STORAGE_REGION_KEY } from '../data/app'
import { useRegionContext } from '../context/RegionContext'
import ModalCloseIcon from '../assets/icons/ModalCloseIcon'

interface Props { }

const time = 90

const items = [
    {
        enabled: true,
        regions: ['AE', 'SA', 'OM', 'JO', 'QA', 'KW', 'BH'],
        image: "/images/Image_How to play.png",
        title: 'Can you stop Spot™?',
        description: `
            <span>Spot™ - Spider-man’s™ new nemesis - has<span>
            <span>stolen our flavours & scattered them across the</span>
            <span>Flavour-Verse. Help us get them back!</span>
        `
    },
    {
        enabled: true,
        regions: ['AE', 'SA', 'OM', 'JO', 'QA', 'KW', 'BH'],
        image: "/images/Image_How to play-1.png",
        title: 'Your mission',
        description: `
            <span>Locate the portals & capture the ice creams</span>
            <span>with your web-shooter. You’ve got ${time} seconds to</span>
            <span>find as many as you can.</span>
        `
    },
    {
        enabled: true,
        regions: ['AE', 'OM', 'JO'],
        image: "/images/Image_How to play-2.png",
        title: 'Unlock bonuses',
        description: `
            <span>Catch 1 ice cream every 3 seconds to</span>
            <span>unlock bonus points.</span>
            <span>The higher your score, the more you can win.</span>
        `
    },
    {
        enabled: true,
        regions: ['AE', 'OM', 'JO'],
        image: "/images/Image_How to play-4.png",
        title: 'Win prizes',
        description: process.env.REACT_APP_SITE_LOCATION === 'AE' ?
            `
                <span>Save our flavours and</span>
                <span>get rewarded with</span>
                <span>awesome prizes.</span>
            ` :
            `
                <div>10 points = 2-for-1 ice cream code</div>
                <div>25 points = Spider-Man™ Squishy</div>
                <div>30 points = trip to NYC competition entry</div>
            `
    },
    {
        enabled: true,
        regions: ['AE', 'SA', 'OM', 'JO', 'QA', 'KW', 'BH'],
        image: "/images/Image_How to play-3.png",
        title: 'Collect wallpapers',
        description: `
            <span>Collect all six exclusive “Spider-Verse”</span>
            <span>wallpapers - unlock a new one each</span>
            <span>time you play.</span>
        `
    },
];

export const Instructions = ({ }: Props) => {
    const { region } = useRegionContext()

    const itemsFiltered = items.filter((item) => {
        return item.enabled && (!region || region && item.regions.includes(region))
    })

    return (
        <div className={clsx(styles.root, 'text-center')}>
            <video
                className={styles.background}
                autoPlay
                muted
                playsInline
                loop
            >
                <source src="https://d2kflxmfwybvzl.cloudfront.net/video/background-landing.mp4" type="video/mp4" />
            </video>
            <div className="absolute z-[1] right-0 top-0 my-[20px] mx-[27px]">
                <Link to="/"><ModalCloseIcon /></Link>
            </div>
            <Wrapper>
                <img
                    className='mt-[10px] mx-auto'
                    src="https://d2kflxmfwybvzl.cloudfront.net/images/lockup-flavourverse.png"
                    width="254"
                    height="132"
                />
            </Wrapper>
            <InstructionsCarousel items={itemsFiltered} />
            <Wrapper>
                <div className="mt-[40px]">
                    <img
                        src="https://d2kflxmfwybvzl.cloudfront.net/images/lockup-spiderman.gif"
                        width="315"
                        height="122"
                        className='mx-auto'
                    />

                    {/* <div>Only In Cinemas</div> */}
                </div>
                <Paragraph size="xs" className="my-[20px] text-white">
                    © & ™ 2023 MARVEL. ©2023 SPAI. All Rights Reserved. Check the classification. T&Cs Apply.
                </Paragraph>
            </Wrapper>
        </div>
    )
}

interface InstructionsItem {
    image: string
    title: string
    description: string
}

interface InstructionsCarouselProps {
    items: InstructionsItem[]
}

const InstructionsCarousel = ({ items }: InstructionsCarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isFirst, setIsFirst] = useState(true)
    const [isLast, setIsLast] = useState(false)

    useEffect(() => {
        if (currentIndex === 0) {
            setIsFirst(true)
        } else {
            setIsFirst(false)
        }

        if (currentIndex === items.length - 1) {
            setIsLast(true)
        } else {
            setIsLast(false)
        }
    }, [currentIndex])

    const handleClickPrevious = () => {
        setCurrentIndex((currentIndex) => {
            return currentIndex - 1
        })
    }

    const navigate = useNavigate()
    const handleClickNext = (isLast: boolean) => {
        if (!isLast) {
            setCurrentIndex((currentIndex) => {
                return currentIndex + 1
            })
        } else {
            navigate('/game')
        }
    }

    return (
        <>
            <div className={styles.items}>
                {
                    items.map((item, index) => {
                        return (
                            <div key={index} className={clsx(styles.item, index === currentIndex && styles.itemActive)}>
                                <img
                                    className="mx-auto"
                                    src={item.image}
                                    width="414"
                                    height="280"
                                    alt=""
                                />
                                <Wrapper>
                                    <div className='mt-[6px] font-comic text-brand-yellow text-sm tracking-widest'>
                                        {index + 1} - {items.length}
                                    </div>
                                    <LayeredHeading
                                        variant="light"
                                        text={item.title}
                                        isPlain={false}
                                        className='text-[48px] mt-[20px] mb-[10px]'
                                    />
                                    <div
                                        className='text-sm'
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                    />
                                </Wrapper>
                            </div>
                        )
                    })
                }
            </div>

            <Wrapper>
                <div className="flex justify-between w-full space-x-3 mt-[34px]">
                    <Button
                        variation="light"
                        as="button"
                        icon={
                            <PreviousArrow className="my-[5px] w-[35px] scale-90" />
                        }
                        iconText
                        onClick={() => {
                            handleClickPrevious()
                        }}
                        disabled={isFirst}
                    />
                    <Button
                        variation="dark"
                        as="button"
                        text={!isLast ? "Next" : "Play the game"}
                        isWide
                        onClick={() => {
                            handleClickNext(isLast)
                        }}
                    />
                </div>
            </Wrapper>
        </>
    )
}