import Button from '../parts/Button'
import Paragraph from '../parts/Paragraph'
import { useModalContext } from "../../context/ModalContext";
import ModalCloseIcon from '../../assets/icons/ModalCloseIcon';
import { useEffect } from 'react';
import { LOCAL_STORAGE_REGION_KEY } from '../../data/app';
import RegionModal from './RegionModal';
import { useRegionContext } from '../../context/RegionContext';

const RegionModalSet = () => {
    const { showModal } = useModalContext();
    const { region } = useRegionContext()

    useEffect(() => {
        setTimeout(() => {
            if (!region && process.env.REACT_APP_SITE_LOCATION === 'AE') {
                showModal({ content: <RegionModal /> });
            }
        }, 20);
    }, []);

    return null
};

export default RegionModalSet;
